define('merlin/initializers/load-services', ['exports', 'merlin/services/event_bus'], function (exports, _merlinServicesEvent_bus) {
	exports.initialize = initialize;

	function initialize(application) {
		var eventBus = _merlinServicesEvent_bus['default'].create();

		application.register('event-bus:current', eventBus, {
			instantiate: false
		});

		application.inject('component', 'EventBus', 'event-bus:current');
		application.inject('controller', 'EventBus', 'event-bus:current');
		application.inject('route', 'EventBus', 'event-bus:current');
	}

	exports['default'] = {
		name: 'load-services',
		initialize: initialize
	};
});