define("merlin/components/form-button", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var inject = _ember["default"].inject;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({

    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    tools: inject.service(),

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    tagName: "button",
    classNameBindings: ["classes", "isDisabled", "error"],
    attributeBindings: ["type"],

    /////////////////////////////////////
    ////////  Lifecycle Methods  ////////
    /////////////////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      this.defaults = {
        id: null,
        actionName: "submit",
        actionParameter: null,
        errorLabel: "Complete the form.",
        classes: "button blue",
        label: "Submit",
        type: "submit",
        validate: true,
        loading: false
      };
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var loading = get(this, "settings.loading");
      if (!this.tools.isEmpty(loading)) {
        set(this, "isLoading", loading);
      }
    },

    ///////////////////////////////////////
    ////////  Computed Properties  ////////
    ///////////////////////////////////////

    classes: computed("settings", function () {
      var settings = get(this, "settings");
      var classes = get(settings, "classes") || "";
      var buttonId = get(settings, "id") || "";
      return classes + " " + buttonId;
    }),

    isLoading: computed("settings", function () {
      var settings = get(this, "settings");
      var loading = get(settings, "loading");
      return get(this, "tools").isEmpty(loading) ? false : loading;
    }),

    isValidated: computed("settings", function () {
      var settings = get(this, "settings");
      var validate = get(settings, "validate");
      return get(this, "tools").isEmpty(validate) ? false : validate;
    }),

    settings: computed('defaults', 'options', function () {
      _ember["default"].assign(this.get('defaults'), this.get('options'));
      return this.get('defaults');
    }),

    type: computed("settings", function () {
      var settings = get(this, "settings");
      var type = get(settings, "type");
      return get(this, "tools").isEmpty(type) ? false : type;
    }),

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    click: function click() {

      if (get(this, "type") === "submit" && !get(this, "error") && !get(this, "isDisabled")) {
        set(this, "isLoading", true);
      }

      if (!get(this, "isLoading")) {
        var tools = get(this, "tools");
        var settings = get(this, "settings");
        var actionName = get(settings, "actionName");

        if (!tools.isEmpty(actionName)) {
          var onClick = get(this, "onClick");
          if (tools.isFunction(onClick)) {
            var actionParameter = get(settings, "actionParameter");
            var buttonId = get(this, "settings.id");

            if (tools.isEmpty(actionParameter)) {
              onClick(actionName, false, buttonId);
            } else {
              onClick(actionName, actionParameter, buttonId);
            }
          } else {
            throw new Error("No onClick action was defined for the form-button.");
          }
        }
      }
    }
  });
});