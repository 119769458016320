define('merlin/controllers/application', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
	exports['default'] = _ember['default'].Controller.extend({

		init: function init() {
			this._super.apply(this, arguments);
			this.EventBus.subscribe("sendNotification", this, "setNotification");
		},

		isBeta: _ember['default'].computed('', function () {
			return _merlinConfigEnvironment['default'].beta;
		}),

		testing: _ember['default'].computed('config.environment', function () {
			return _merlinConfigEnvironment['default'].environment === "test";
		}),

		showingNotification: false,
		notification: null,

		actions: {
			openNotificationModal: function openNotificationModal() {
				this.set('showingNotification', true);
			},

			closeNotificationModal: function closeNotificationModal() {
				this.set('showingNotification', false);
			}
		},

		setNotification: function setNotification(message) {
			this.set('notification', message);
			this.set('showingNotification', true);
			this.startNotificationTimer();
		},

		startNotificationTimer: function startNotificationTimer() {
			var time = 6000;

			_ember['default'].run.later(this, function () {
				this.set('showingNotification', false);
			}, time);
		}
	});
});