define('merlin/initializers/error-handler', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'errorHandler', 'service:errorHandler');
    application.inject('controller', 'errorHandler', 'service:errorHandler');
    application.inject('component', 'errorHandler', 'service:errorHandler');

    // Inject into specific services
    application.inject('service:listManager', 'errorHandler', 'service:errorHandler');
    application.inject('service:reviewManager', 'errorHandler', 'service:errorHandler');
    application.inject('service:peopleManager', 'errorHandler', 'service:errorHandler');
    application.inject('service:authenticator', 'errorHandler', 'service:errorHandler');
    application.inject('service:videoAuthenticator', 'errorHandler', 'service:errorHandler');
  }

  exports['default'] = {
    name: 'error-handler',
    initialize: initialize
  };
});