define('merlin/models/video', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
	var computed = _ember['default'].computed;
	var get = _ember['default'].get;
	exports['default'] = _emberDataModel['default'].extend({
		access: (0, _emberDataAttr['default'])('string'),
		athletes: (0, _emberDataRelationships.hasMany)('athlete', { async: false }),
		coaches: (0, _emberDataRelationships.hasMany)('coach', { async: false }),
		created: (0, _emberDataAttr['default'])('string'),
		created_by: (0, _emberDataAttr['default'])('string'),
		creator_id: (0, _emberDataAttr['default'])('string'),
		duration: (0, _emberDataAttr['default'])('number'),
		lists: (0, _emberDataRelationships.hasMany)('list', { async: true }),
		reviews: (0, _emberDataRelationships.hasMany)('review', { async: false }),
		state: (0, _emberDataAttr['default'])('string'),
		tags: (0, _emberDataRelationships.hasMany)('tag', { async: false }),
		thumbnail: (0, _emberDataAttr['default'])('string'),
		type: (0, _emberDataAttr['default'])('string'),
		played: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),

		inCloud: computed('state', function () {
			return get(this, 'state') === 'c';
		}),

		typeComputed: computed("type", function () {
			var type = get(this, 'type');
			if (type === "c") {
				return "capture";
			} else {
				return "review";
			}
		}),

		tagsConcatenated: computed("tags", function () {
			var tags = get(this, "tags");
			var cat = "";
			tags.forEach(function (item, index, tags) {
				cat = cat + get(item, 'id');
				if (index !== tags.length - 1) {
					cat = cat + ', ';
				}
			});

			return cat;
		}),

		athletesConcatenated: computed("athletes", function () {
			var athletes = get(this, "athletes");
			var cat = "";
			athletes.forEach(function (item, index, athletes) {
				cat = cat + get(item, 'nameAbbreviated');
				if (index !== athletes.length - 1) {
					cat = cat + ', ';
				}
			});

			return cat;
		})
	});
});