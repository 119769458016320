define("merlin/components/settings-list", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var inject = _ember["default"].inject;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({

    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    currentRoute: inject.service(),

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    tagName: "",
    classNames: ["container-list-tableview", "rounded", "settings"],

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    currentActiveSetting: "account",

    ///////////////////////////////////////
    ////////  Computed Properties  ////////
    ///////////////////////////////////////

    currentNode: computed("currentRoute.currentNode", function () {
      return get(this, "currentRoute.currentNode");
    }),

    /////////////////////////////////////
    ////////  Lifecycle Methods  ////////
    /////////////////////////////////////

    didReceiveAttrs: function didReceiveAttrs() {
      if (get(this, "transitioning")) {
        set(this, "currentActiveSetting", "");
      }

      var currentNode = get(this, "currentNode");
      var currentActiveSetting = get(this, "currentActiveSetting");

      if (currentActiveSetting !== currentNode) {
        set(this, "currentActiveSetting", currentNode);
      }
    },

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      setCurrentActiveSetting: function setCurrentActiveSetting(key) {
        set(this, "currentActiveSetting", key);
      }
    }
  });
});