define('merlin/services/video-authenticator', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
	var Service = _ember['default'].Service;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	exports['default'] = Service.extend({

		session: inject.service(),

		getToken: function getToken(user) {
			return user.getToken();
		},

		getAuthenticatedURL: function getAuthenticatedURL(videoModel, token) {

			if (!token) {
				get(this, "errorHandler").throwIt(new Error("You must provide a token to getAuthenticatedURL()"));
			}

			var videoID = undefined;

			if (videoModel) {
				videoID = get(videoModel, 'id');
			} else {
				this.errorHandler.throwIt(new Error("You must provide a videoModel to getAuthenticatedURL()"));
			}

			var session = get(this, 'session');
			var currentUser = get(session, 'currentUser');
			var uid = currentUser.uid;

			var url = _merlinConfigEnvironment['default'].falconer + "videos/" + videoID + "?userId=" + uid + "&token=" + token;
			return url;
		}
	});
});