define("merlin/components/x-form", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var inject = _ember["default"].inject;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({

    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    tools: inject.service(),

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    externalSubmission: false,
    isLoading: false,

    ///////////////////////////////////////
    ////////  Computed Properties  ////////
    ///////////////////////////////////////

    formIsInvalid: computed("data", function () {
      var data = get(this, "data");
      return get(data, "isInvalid");
    }),

    /////////////////////////////////////
    ////////  Lifecycle Methods  ////////
    /////////////////////////////////////

    didReceiveAttrs: function didReceiveAttrs() {
      var tools = get(this, "tools");
      var serverErrors = get(this, "serverErrors");

      if (!tools.isEmpty(serverErrors) && Object.keys(serverErrors).length !== 0 && serverErrors.constructor === Object) {
        get(this, "data").addError(serverErrors.key, [serverErrors.validation]);
        set(this, "formIsInvalid", true);
      }
    },

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      buttonClicked: function buttonClicked(actionName) {

        if (actionName !== "submit") {
          var action = get(this, actionName);

          for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            args[_key - 1] = arguments[_key];
          }

          if (get(this, "tools").isFunction(action)) {
            get(this, actionName).apply(undefined, args);
          } else {
            var buttonAction = get(this, "buttonAction");
            if (get(this, "tools").isFunction(buttonAction)) {
              get(this, "buttonAction").apply(undefined, [actionName].concat(args));
            } else {
              throw new Error("The function " + actionName + " does not exist in the form’s scope and you have not speficied a buttonAction at the form’s invocation scope.");
            }
          }
        }
      },

      formSubmitted: function formSubmitted() {
        // if(!get(this,"isLoading")) {
        var buttons = get(this, "buttons");
        // const buttonId = get(args,"lastObject");
        // const clickedButton = buttons.findBy("id", buttonId);
        var clickedButton = buttons.findBy("type", "submit");

        var formAction = get(this, "formAction");
        var data = get(this, "data");
        data.validate();

        if (get(data, "isValid")) {
          set(this, "isLoading", true);
          data.save();
          get(this, "onSubmit")(formAction, data);
        } else {
          set(this, "formIsInvalid", true);
          if (!this.tools.isEmpty(clickedButton)) {
            clickedButton.loading = false;
          }
        }
        // }
      },

      setInputValue: function setInputValue(fieldId, value) {
        var data = get(this, "data");
        set(data, fieldId, value);
        data.validate();
        set(this, "formIsInvalid", get(data, "isInvalid"));

        if (get(data, "isValid")) {
          var onDidBecomeValid = get(this, "onDidBecomeValid");
          if (get(this, "tools").isFunction(onDidBecomeValid)) {
            get(this, "onDidBecomeValid")();
          }
        } else {
          var onDidBecomeInvalid = get(this, "onDidBecomeInvalid");
          if (get(this, "tools").isFunction(onDidBecomeInvalid)) {
            get(this, "onDidBecomeInvalid")();
          }
        }
      },

      validateField: function validateField() {
        get(this, "data").validate();
      }
    }
  });
});