define('merlin/models/user', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
	var computed = _ember['default'].computed;
	var get = _ember['default'].get;
	exports['default'] = _emberDataModel['default'].extend({
		fname: (0, _emberDataAttr['default'])('string'),
		lname: (0, _emberDataAttr['default'])('string'),
		authorizations: (0, _emberDataRelationships.hasMany)('authorization', { async: false }),
		email: (0, _emberDataAttr['default'])('string'),
		tos_accepted: (0, _emberDataAttr['default'])('boolean'),
		email_confirmed: (0, _emberDataAttr['default'])('boolean'),
		showMeNews: (0, _emberDataAttr['default'])('boolean', { defaultValue: true }),
		lastVersionViewed: (0, _emberDataAttr['default'])('string'),

		name: computed('fname', 'lname', function () {
			var fname = get(this, 'fname');
			var lname = get(this, 'lname');
			return fname + ' ' + lname;
		})
	});
});