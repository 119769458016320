define('merlin/services/session-manager', ['exports', 'ember'], function (exports, _ember) {
	var Service = _ember['default'].Service;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	exports['default'] = Service.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		session: inject.service(),
		store: inject.service(),

		///////////////////////////
		////////  Methods  ////////
		///////////////////////////

		smartTransition: function smartTransition(transitioner, defaultTarget) {
			var attemptedTransition = get(this, 'session.attemptedTransition');

			if (this.tools.isEmpty(attemptedTransition)) {
				this._transition(transitioner, defaultTarget);
			} else {
				this._transition(transitioner, attemptedTransition.targetName);
			}
		},

		_transition: function _transition(transitioner, target) {
			if (typeof transitioner.transitionTo === "function") {
				transitioner.transitionTo(target);
			} else if (typeof transitioner.transitionToRoute === "function") {
				transitioner.transitionToRoute(target);
			} else {
				throw new Error("transitioner must be a route or controller");
			}
		},

		unload: function unload() {
			var debug = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

			if (debug) {
				console.log("unloading...");
			}
			var store = get(this, "store");

			var athletes = store.peekAll('athlete');
			var authorizations = store.peekAll('authorization');
			var coaches = store.peekAll('coach');
			var lists = store.peekAll('list');
			var organization_people = store.peekAll('organization_person');
			var personFavorites = store.peekAll('person_favorite');
			var personInboxes = store.peekAll('person_inbox');
			var personLists = store.peekAll('person_list');
			var personListLists = store.peekAll('person_list_list');
			var personReviews = store.peekAll('person_review');
			var person_shared_with_me = store.peekAll('person_shared_with_me');
			var personTodos = store.peekAll('person_todo');
			var reviews = store.peekAll('review');
			var tags = store.peekAll('tag');
			var users = store.peekAll('user');
			var videos = store.peekAll('video');

			var local = [athletes, authorizations, coaches, lists, organization_people, personFavorites, personInboxes, personLists, personListLists, personReviews, person_shared_with_me, personTodos, reviews, tags, users, videos];

			local.forEach(function (array) {
				array.forEach(function (record) {
					store.unloadRecord(record);
					if (debug) {
						console.log('unloaded ' + get(record, "id"));
					}
				});
			});
		}
	});
});