define('merlin/controllers/videos/search', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    queryParams: ['filter', 'list_name', 'list_id'],

    keys: ["tagsTransformed.name", "athletesTransformed.fname", "athletesTransformed.lname", "coachesTransformed.fname", "coachesTransformed.lname", "created_by"],

    ///////////////////////////////////////
    ////////  Computed Properties  ////////
    ///////////////////////////////////////

    navigationOptions: computed("filter", "list_name", function () {
      var filter = get(this, "filter");

      var TITLES = {
        _default: "All",
        inbox: "Inbox",
        todo: "To-do",
        favorites: "Favorites",
        captures: "My Captures",
        reviews: "My Reviews",
        shared: "Shared With Me"
      };

      var title = TITLES[filter] || TITLES["_default"];

      if (filter === "list") {
        title = get(this, "list_name");
      }

      return {
        type: "modal",
        title: title,
        border: false,
        left: {
          icon: "back",
          label: "Videos",
          actionName: "goTo",
          actionParameter: "videos"
        }
      };
    }),

    showCancel: computed("filter", function () {
      var filter = get(this, "filter");
      return this.tools.isNotEmpty(filter);
    }),

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    resetParams: function resetParams() {
      set(this, "filter", "");
      set(this, "list_name", "");
      set(this, "list_id", "");
    },

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      onCancelSearch: function onCancelSearch() {
        var _this = this;

        var filter = get(this, "filter");

        var TARGETS = {
          _default: "videos.all",
          inbox: "videos.inbox",
          todo: "videos.todo",
          favorites: "videos.favorites",
          captures: "videos.captures",
          reviews: "videos.reviews",
          shared: "videos.shared"
        };

        var target = TARGETS[filter] || TARGETS["_default"];

        if (filter === "list") {
          var lid = get(this, "list_id");
          this.transitionToRoute("videos.list", lid).then(function () {
            _this.resetParams();
          });
        } else {
          this.transitionToRoute(target).then(function () {
            _this.resetParams();
          });
        }
      }
    }
  });
});