define('merlin/services/error-handler', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
	exports['default'] = _ember['default'].Service.extend({
		log: function log(error) {
			var usefulError = this._convertToError(error);
			console.error(usefulError);
			return true;
		},

		throwIt: function throwIt(error) {

			var usefulError = this._convertToError(error);
			// console.error("errorHandler: "+ usefulError);
			throw usefulError;
		},

		_convertToError: function _convertToError(orginalErr) {

			var newErr = undefined;

			// console.log(Ember.typeOf(orginalErr));

			if (_ember['default'].typeOf(orginalErr) === 'object' && !orginalErr.isCustom) {

				var msg = orginalErr.responseText || orginalErr.message || orginalErr.toString();
				var fileName = orginalErr.fileName;
				var lineNumber = orginalErr.lineNumber;

				var _status = orginalErr.status;
				var code = orginalErr.code;
				var stack = orginalErr.stack;

				if (fileName && lineNumber) {
					newErr = new Error(msg, fileName, lineNumber);
				} else if (fileName) {
					newErr = new Error(msg, fileName);
				} else if (lineNumber) {
					newErr = new Error(msg, null, lineNumber);
				} else {
					newErr = new Error(msg);
				}

				if (_status) {
					newErr.status = _status;
				}

				if (code) {
					newErr.code = code;
				}

				if (stack) {
					newErr.stack = stack;
				}
			} else {
				newErr = orginalErr;
			}

			return newErr;
		},

		isNoRecordError: function isNoRecordError(error, pid, endpoint) {
			var debug = arguments.length <= 3 || arguments[3] === undefined ? false : arguments[3];

			var message = "no record was found at " + _merlinConfigEnvironment['default'].firebase.databaseURL + "/" + endpoint + "/" + pid;
			if (debug) {
				console.log(message);
				console.log(error.message);
			}
			return error.message === message;
		}
	});
});