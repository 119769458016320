define("merlin/controllers/videos/inbox/video", ["exports", "ember"], function (exports, _ember) {
	var Controller = _ember["default"].Controller;
	exports["default"] = Controller.extend({

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		navigationOptions: {
			type: "video",
			title: "Watch Video",
			left: {
				icon: "back",
				label: "Inbox",
				actionName: "goTo",
				actionParameter: "videos.inbox",
				mobileOnly: true
			}
		},

		toolbarOptions: {
			buttons: [
				// {
				// 	icon: "list",
				// 	actionName: "clickedAddToList"
				// },
				// {
				// 	icon: "remove",
				// 	actionName: "clickedRemoveFromList"
				// },
				// {
				// 	icon: "trash",
				// 	actionName: "clickedDeleteVideo",
				// 	theme: {
				// 		color: "red"
				// 	}
				// }
			]
		}
	});
});