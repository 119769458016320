define('merlin/routes/videos/list', ['exports', 'ember'], function (exports, _ember) {
	var Route = _ember['default'].Route;
	var get = _ember['default'].get;
	var RSVP = _ember['default'].RSVP;
	exports['default'] = Route.extend({

		model: function model(params) {
			var _this = this;

			var promises = {
				list: this.store.findRecord('list', params.list_id),
				videos: this.store.findRecord('list', params.list_id).then(function (list) {
					return get(list, 'videos');
				}).then(function (videos) {
					return videos.filterBy('inCloud', true);
				})['catch'](function (error) {
					_this.errorHandler.throwIt(error);
				})
			};

			return RSVP.hash(promises)['catch'](function (error) {
				get(_this, 'errorHandler').throwIt(error);
			});
		}
	});
});