define("merlin/components/bar-tool", ["exports", "ember"], function (exports, _ember) {
	var Component = _ember["default"].Component;
	var computed = _ember["default"].computed;
	var get = _ember["default"].get;
	var assign = _ember["default"].assign;
	exports["default"] = Component.extend({

		init: function init() {
			this._super.apply(this, arguments);
			this.defaults = {
				buttons: [],
				flex: true,
				theme: {
					background: "dark",
					buttonColor: "white"
				}
			};
		},

		classNames: ['container-bar-tool', 'videoList'],
		classNameBindings: ['theme', 'flex'],

		theme: computed('settings', function () {
			return get(this, 'settings').theme.background;
		}),

		flex: computed('settings', function () {
			return get(this, 'settings').flex;
		}),

		settings: computed('defaults', 'options', function () {
			assign(get(this, 'defaults'), get(this, 'options'));
			return get(this, 'defaults');
		}),

		buttonsLength: computed('settings', function () {
			var buttons = get(this, 'settings').buttons;
			return get(buttons, 'length') - 1;
		}),

		actions: {
			clickedButton: function clickedButton(actionName, actionParameter) {
				var toolbarAction = get(this, 'onToolbarAction');
				if (this.tools.isFunction(toolbarAction)) {
					get(this, 'onToolbarAction')(actionName, actionParameter);
				} else {
					throw new Error("There is no function called \"onToolbarAction\" at this component’s parent’s scope.");
				}
			}
		}
	});
});