define('merlin/routes/issues', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({

		model: function model() {
			return this.get('privateBrowsingContent');
		},

		privateBrowsingContent: {
			title: "Private Browsing Detected",
			message: "Unfortunately, APEX does not work while using Private Browsing. If you can turn if off, great! However, we understand privacy is important—so we provide another option: If you are on an iPhone or iPad, you can view your videos using the APEX iOS app.",
			returnToSource: false,
			keepParams: false,
			sendToAppStore: true,
			actionText: "Get APEX on the App Store."
		}
	});
});