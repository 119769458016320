define('merlin/services/list-manager', ['exports', 'ember'], function (exports, _ember) {
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Service.extend({

    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    authenticator: inject.service(),
    sessionManager: inject.service(),
    store: inject.service(),
    session: inject.service(),

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      this.set('router', _ember['default'].getOwner(this).lookup('router:main'));
    },

    fetchVideos: function fetchVideos(modelName, endpointModelName) {
      var _this = this;

      var showOnlyInCloud = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

      var authenticator = get(this, "authenticator");
      var organization = get(this, "session.content.organization");
      var pid = get(this, "session.content.pid");

      return get(this, 'store').findRecord(modelName, pid).then(function (List) {
        return List.get('videos');
      }).then(function (videos) {
        if (showOnlyInCloud) {
          return videos.filterBy('inCloud', true);
        } else {
          return videos;
        }
      })['catch'](function (error) {
        var endpoint = typeof endpointModelName === 'string' ? 'organizations/' + organization + '/' + endpointModelName : 'organizations/' + organization + '/' + modelName;
        if (_this.errorHandler.isNoRecordError(error, pid, endpoint)) {
          return [];
        } else {
          get(_this, "sessionManager").unload();
          return authenticator.safelyCloseSession().then(function () {
            return get(_this, "router").transitionTo('login');
          }).then(function () {
            _this.errorHandler.throwIt(error);
          })['catch'](function (reason) {
            _this.errorHandler.throwIt(reason);
          });
        }
      });
    }
  });
});