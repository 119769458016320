define('merlin/services/authenticator', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
	var Service = _ember['default'].Service;
	var inject = _ember['default'].inject;
	var RSVP = _ember['default'].RSVP;
	var get = _ember['default'].get;
	exports['default'] = Service.extend({

		session: inject.service(),
		sessionManager: inject.service(),
		firebaseApp: inject.service(),
		ajax: inject.service(),

		authenticate: function authenticate(email, password) {
			var _this = this;

			return new RSVP.Promise(function (resolve, reject) {
				get(_this, "session").open("firebase", {
					provider: 'password',
					email: email,
					password: password
				}).then(function (data) {
					resolve(data);
				})['catch'](function (reason) {
					reject(reason);
				});
			});
		},

		getAccountType: function getAccountType(email) {
			var server = _merlinConfigEnvironment['default'].falconer;
			var endpoint = 'account/lookup/' + email;

			return get(this, "ajax").request('' + server + endpoint);
		},

		authenticateWithToken: function authenticateWithToken(token) {
			var _this2 = this;

			return new RSVP.Promise(function (resolve, reject) {
				get(_this2, "session").open("firebase", {
					provider: 'custom',
					token: token
				}).then(function (data) {
					resolve(data);
				})['catch'](function (reason) {
					reject(reason);
				});
			});
		},

		safelyAuthenticate: function safelyAuthenticate(email, password) {
			var _this3 = this;

			get(this, "sessionManager").unload();
			return this.safelyCloseSession().then(function () {
				return _this3.authenticate(email, password);
			});
		},

		safelyCloseSession: function safelyCloseSession() {
			var session = get(this, 'session');

			return new RSVP.Promise(function (resolve, reject) {
				if (get(session, 'isAuthenticated')) {
					var stateMachine = get(session, "stateMachine");
					var state = get(stateMachine, "currentStateName");

					if (state === 'closing') {
						stateMachine.send('finishClose');
						resolve();
					} else if (state === 'unauthenticated') {
						resolve();
					} else {
						session.close().then(function () {
							resolve();
						})['catch'](function (error) {
							reject(error);
						});
					}
				} else {
					resolve();
				}
			});
		}
	});
});