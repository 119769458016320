define("merlin/components/my-dialogue", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var get = _ember["default"].get;

  /**
   * A modal dialogue component.
   *
   * @class my-dialogue
   */

  exports["default"] = Component.extend({

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    classNames: ["container-modal-outer"],
    classNameBindings: ["isActive"],

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    /**
     * Determines whether to display the dialogue.
     *
     * @property isActive
     * @type {Boolean}
     * @default false
     */
    isActive: false,

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {

      /**
       * Calls the onCloseModal handlebars action.
       *
       * @method closeModal
       */
      closeModal: function closeModal() {
        get(this, "onCloseModal")();
      },

      /**
       * Handles sending externally defiend dialogue actions to the parent scope.
       *
       * @method onDialogueAction
       */
      onDialogueAction: function onDialogueAction() {
        var dialogueAction = get(this, 'dialogueAction');
        if (this.tools.isFunction(dialogueAction)) {
          get(this, 'dialogueAction').apply(undefined, arguments);
        } else {
          throw new Error("There is no function \"dialogueAction\" at the calling scope.");
        }
      }
    }
  });
});