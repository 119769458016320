define('merlin/components/video-player', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
	var Component = _ember['default'].Component;
	var inject = _ember['default'].inject;
	var computed = _ember['default'].computed;
	var get = _ember['default'].get;
	exports['default'] = Component.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		authenticator: inject.service(),
		session: inject.service(),
		sessionManager: inject.service(),
		currentRoute: inject.service(),
		videoAuthenticator: inject.service(),
		ajax: inject.service(),

		///////////////////////////////////////
		////////  Component Defintion  ////////
		///////////////////////////////////////

		classNames: ['container-videoPlayer'],

		/////////////////////////////////////
		////////  Lifecycle Methods  ////////
		/////////////////////////////////////

		init: function init() {
			this._super.apply(this, arguments);
			this.set('router', _ember['default'].getOwner(this).lookup('router:main'));
		},

		didInsertElement: function didInsertElement() {
			var vid = get(this, "video.id");
			get(this, "currentRoute").setCurrentDynamicSegment(vid);
		},

		///////////////////////////////////////
		////////  Computed Properties  ////////
		///////////////////////////////////////

		authenticatedURL: computed("video", function () {
			var video = get(this, 'video');
			var token = get(this, 'token');
			return get(this, 'videoAuthenticator').getAuthenticatedURL(video, token);
		}),

		playerErrorMessage: computed('video', function () {
			if (get(this, 'video.state') !== 'c') {
				var pid = get(this, "session.content.pid");
				var created_by = get(this, 'video.created_by');
				if (get(this, 'video.creator_id') === pid) {
					return 'This video is still being uploaded.\nPlease check back in a few minutes or you can check the upload status within APEX on the iPhone or iPad device that you used to create the video.';
				} else {
					return 'This video is still being uploaded.\nPlease check back in a few minutes or ask ' + created_by + ' to check the video\'s status within the APEX outbox on their iPhone or iPad.';
				}
			} else {
				return null;
			}
		}),

		hasPlayerError: computed('playerErrorMessage', function () {
			return get(this, 'playerErrorMessage') != null;
		}),

		///////////////////////////
		////////  Actions  ////////
		///////////////////////////

		actions: {
			onError: function onError() {
				var _this = this;

				// const url = `${config.falconer}account/subscription-status`;
				// const uid = this.get('session.content.uid');
				// const token = get(this,'token');
				//
				// get(this,'ajax').request(url,{
				//   method: 'GET',
				// 	headers: {
				// 		"X-Firebase-UserId": uid,
				// 		"X-Firebase-Token": token
				// 	}
				// })
				// .then((status) => {
				//   console.log(status);
				// });

				var uid = this.get('session.content.uid');
				var token = get(this, 'token');
				var url = _merlinConfigEnvironment['default'].falconer + 'account/subscription-status?userId=' + uid + '&token=' + token;

				get(this, 'ajax').request(url, {
					method: 'GET'
				}).then(function (response) {
					console.log(response);

					// If the subscription has expired.
					if (!response.data.active) {
						get(_this, "sessionManager").unload();

						/*true*/get(_this, 'authenticator').safelyCloseSession().then(function () {
							get(_this, "router").transitionTo("login");
						})['catch'](function (error) {
							_this.errorHandler.throwIt(error);
						});
					}
				});
			}
		}
	});
});