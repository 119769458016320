define("merlin/controllers/videos/captures/video", ["exports", "merlin/controllers/videos/inbox/video"], function (exports, _merlinControllersVideosInboxVideo) {
	exports["default"] = _merlinControllersVideosInboxVideo["default"].extend({
		navigationOptions: {
			type: "video",
			title: "Watch Video",
			left: {
				icon: "back",
				label: "My Captures",
				actionName: "goTo",
				actionParameter: "videos.captures",
				mobileOnly: true
			}
		}
	});
});
// import Ember from 'ember';