define("merlin/controllers/videos/inbox", ["exports", "ember"], function (exports, _ember) {
	var Controller = _ember["default"].Controller;
	exports["default"] = Controller.extend({

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		navigationOptions: {
			type: "list",
			title: "Inbox",
			left: {
				icon: "back",
				label: "Videos",
				actionName: "goTo",
				actionParameter: "videos"
			}
		},

		toolbarOptions: {
			buttons: [{
				icon: "search",
				actionName: "openSearch",
				actionParameter: "inbox"
			}]
		}
	});
});