define("merlin/controllers/videos/index", ["exports", "ember"], function (exports, _ember) {
	var Controller = _ember["default"].Controller;
	exports["default"] = Controller.extend({

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		navigationOptions: {
			type: "index",
			title: "Videos",
			right: {
				icon: "cog",
				actionName: "goTo",
				actionParameter: "settings"
			}
		},

		toolbarOptions: {
			buttons: [{
				icon: "search",
				actionName: "openSearch"
			}, {
				icon: "list",
				actionName: "goTo",
				actionParameter: "videos.manage-lists"
			}]
		},

		///////////////////////////
		////////  Actions  ////////
		///////////////////////////

		actions: {
			clickedListsEmptyState: function clickedListsEmptyState() {
				this.transitionToRoute('videos.manage-lists');
				this.EventBus.publish('clickedAddListButton');
			}
		}
	});
});