define('merlin/services/tools', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    isEmpty: function isEmpty(varToCheck) {
      return varToCheck === "" || varToCheck === undefined || varToCheck === null;
    },

    isNotEmpty: function isNotEmpty(varToCheck) {
      return !this.isEmpty(varToCheck);
    },

    objectIsEmpty: function objectIsEmpty(objectToCheck) {
      if (objectToCheck !== null && objectToCheck !== undefined) {
        return Object.keys(objectToCheck).length === 0 && objectToCheck.constructor === Object;
      } else {
        return true;
      }
    },

    objectIsNotEmpty: function objectIsNotEmpty(objectToCheck) {
      return !this.objectIsEmpty(objectToCheck);
    },

    isFunction: function isFunction(varToCheck) {
      return typeof varToCheck === 'function';
    },

    isString: function isString(varToCheck) {
      return typeof varToCheck === 'string';
    },

    isArray: function isArray(varToCheck) {
      return varToCheck.constructor === Array;
    }
  });
});