define('merlin/models/list', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    athletes: (0, _emberDataRelationships.hasMany)('athlete'),
    coaches: (0, _emberDataRelationships.hasMany)('coach'),
    name: (0, _emberDataAttr['default'])("string"),
    owner: (0, _emberDataAttr['default'])("string"),
    permissions: (0, _emberDataAttr['default'])("string"),
    type: (0, _emberDataAttr['default'])("string"),
    videos: (0, _emberDataRelationships.hasMany)('video', { async: true })
  });
});