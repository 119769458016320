define('merlin/components/logo-link', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    tagName: "",

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    target: 'login',
    defaultClasses: 'logo-mark',
    classes: 'standard',

    ///////////////////////////////////////
    ////////  Computed Properties  ////////
    ///////////////////////////////////////

    logoClasses: computed("defaultClasses", "classes", function () {
      return get(this, "defaultClasses") + ' ' + get(this, "classes");
    })
  });
});