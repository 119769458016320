define("merlin/transitions", ["exports"], function (exports) {
	var compareValues = function compareValues(toValue, fromValue, fromKey, toKey) {
		if (toValue !== undefined && fromValue !== undefined) {
			if (toValue.navigation !== undefined && fromValue.navigation !== undefined) {
				var fv = fromValue.navigation.type;
				var tv = toValue.navigation.type;
				return fv === fromKey && tv === toKey;
			}
		} else {
			return false;
		}
	};

	exports["default"] = function () {

		// Select Video List
		this.transition(this.toValue(function (toValue, fromValue) {
			compareValues(toValue, fromValue, "index", "list");
		}), this.use('to-left'), this.reverse('to-right'));

		// Select Video (Mobile)
		this.transition(this.toValue(function (toValue, fromValue) {
			compareValues(toValue, fromValue, "list", "video");
		}), this.use('to-left'), this.reverse('to-right'));

		// Select First Video (Desktop)
		this.transition(this.fromValue(false), this.toValue(true), this.use('fade'));

		// Select Another Video (Desktop)
		this.transition(this.toValue(function (toValue, fromValue) {
			compareValues(toValue, fromValue, "video", "video");
		}), this.use('crossFade'));

		// Select Modal
		this.transition(this.toValue(function (toValue, fromValue) {
			compareValues(toValue, fromValue, "index", "modal");
		}), this.use('toUp'), this.reverse('toDown'));

		// Select Detail
		this.transition(this.toValue(function (toValue, fromValue) {
			compareValues(toValue, fromValue, "detail", "detail");
		}), this.use('fade'));

		//Opening Settings
		this.transition(this.toRoute("settings"), this.use('toUp'), this.reverse('toDown'));
	};
});