define("merlin/errors/SSOError", ["exports"], function (exports) {
  var SSOError = function SSOError(message) {
    this.isCustom = true;
    this.stack = new Error().stack;
    this.title = "Single Sign-On Failed";
    this.message = "Contact your organization for assistance.";
    this.technicalMessage = message || '';
    this.returnToSource = true;
    this.source = 'login';
    this.actionText = "or Login with an APEX Account";
    this.keepParams = false;
  };

  SSOError.prototype = Object.create(Error.prototype);

  exports["default"] = SSOError;
});