define('merlin/services/search-tools', ['exports', 'ember'], function (exports, _ember) {
	var Service = _ember['default'].Service;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	exports['default'] = Service.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		store: inject.service(),

		///////////////////////////
		////////  Methods  ////////
		///////////////////////////

		convertList: function convertList(list) {
			var _this = this;

			return list.map(function (item) {
				var listItem = item.toJSON({ includeId: true });
				// console.log(listItem);

				if (listItem.tags) {
					var tagsTransformed = _this._transformTags(listItem.tags);
					listItem.tagsTransformed = tagsTransformed;
				}

				if (listItem.athletes) {
					var athletesTransformed = _this._transformPeople(listItem.athletes);
					listItem.athletesTransformed = athletesTransformed;
				}

				// if(listItem.coaches) {
				// 	let coachesTransformed = this._transformPeople(listItem.coaches);
				// 	listItem.coachesTransformed = coachesTransformed;
				// }

				return listItem;
			});
		},

		convertResults: function convertResults(results) {
			var _this2 = this;

			return results.map(function (item) {
				return get(_this2, 'store').findRecord('video', item.id);
			});
		},

		/**
   * Convert tags into a searchable format.
   *
   * @method _transformTags
   * @param {[Relationship]} tags An array of tag models.
   * @return {Array} An array of JSON objects.
   */

		_transformTags: function _transformTags(tags) {
			var arr = [];

			tags.forEach(function (item) {
				// console.log(item,index);
				arr.push({ name: item });
			});

			return arr;
		},

		_transformPeople: function _transformPeople(people) {
			var _this3 = this;

			var type = arguments.length <= 1 || arguments[1] === undefined ? "athlete" : arguments[1];

			var arr = [];

			people.forEach(function (item) {
				get(_this3, "store").findRecord(type, item).then(function (person) {
					var fname = get(person, "fname");
					var lname = get(person, "lname");

					arr.push({ fname: fname, lname: lname });
				});
			});

			return arr;
		}
	});
});