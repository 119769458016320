define("merlin/components/table-cell", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var inject = _ember["default"].inject;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var set = _ember["default"].set;

  /**
   * A component for managing table cell actions and content.
   *
   * @class table-cell
   */

  /**
   * Action triggered when the user clicks the cell. Set in handlebars when calling the component.
   *
   * @property onClick
   * @type {action}
   */

  exports["default"] = Component.extend({

    /**********************
     * Default Properties *
     **********************/

    /**
     * Used to determine if this cell is the active cell.
     *
     * @property activeCellID
     * @type {String}
     * @default ""
     * @optional
     */
    activeCellID: "",

    /**
     * Configure whether the cell has a hover state.
     *
     * @property canHover
     * @type {Boolean}
     * @default true
     */
    canHover: true,

    /**
     * Unique identifier for the cell.
     *
     * @property cellID
     * @type {String}
     * @default ""
     */
    cellID: "",

    /**
     * Configures whether is-active should be managed internally or externally.
     *
     * @property internalActiveManagement
     * @type {Boolean}
     * @default: false
     */
    internalIsActiveManagement: false,

    /**
     * Used to determine whether the mouse has entered the cell.
     *
     * @property isHovered
     * @type {Boolean}
     * @default false
     */
    isHovered: false,

    /**
     * Tells the component to keep its active styles when is-active is true.
     *
     * @property keepHoverWhileActive
     * @type {Boolean}
     * @default true
     */
    keepActiveStyle: true,

    /**
     * Route name to transition to.
     *
     * @property target
     * @type {String}
     * @default ""
     * @optional
     */
    target: "",

    /************************
     * Component Definition *
     ************************/

    tagName: "li",
    classNames: ["cell-tableview"],
    classNameBindings: ["isActive", "isHovered", "keepActiveStyle:keep-active", "classes"],

    /***********************
     * Computed Properties *
     ***********************/

    isActive: computed("currentActiveCell", "cellID", function () {
      return get(this, "currentActiveCell") === get(this, "cellID");
    }),

    /***********
     * Methods *
     ***********/

    init: function init() {
      this._super.apply(this, arguments);
      this.set('router', _ember["default"].getOwner(this).lookup('router:main'));
    },

    /**
     * Sets isHovered to true, if canHover is true. Called when the mouseEnter event fires.
     *
     * @method mouseEnter
     */
    mouseEnter: function mouseEnter() {
      if (get(this, "canHover")) {
        set(this, "isHovered", true);
      }
    },

    /**
     * Sets isHovered to false. Called when the mouseLeave event fires.
     *
     * @method mouseLeave
     */
    mouseLeave: function mouseLeave() {
      set(this, "isHovered", false);
    },

    /**
     * 1) Transitions to another route, if supplied.
     * 2) Sets is-active state.
     * 3) Calls onCLick. Called when the click event fires.
     *
     * @method click
     */
    click: function click() {
      var target = get(this, "target");
      var model = get(this, "model");

      if (target === '') {
        return;
      }

      // If a target is provided, transition.
      if (this.tools.isString(target)) {
        if (model !== undefined) {
          get(this, "router").transitionTo(target, model);
        } else {
          get(this, "router").transitionTo(target);
        }
      }

      // Manage is-active internally if appropriate.
      if (get(this, "internalIsActiveManagement")) {
        var isActive = get(this, "isActive");

        if (isActive) {
          set(this, "currentActiveCell", "");
        } else {
          var cid = get(this, "cellID");
          set(this, "currentActiveCell", cid);
        }
      }

      // Call onClick if it exists.
      var onClick = get(this, "onClick");
      if (this.tools.isFunction(onClick)) {
        get(this, "onClick")();
      }
    }
  });
});