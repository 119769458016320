define("merlin/controllers/videos/list", ["exports", "ember"], function (exports, _ember) {
  var Controller = _ember["default"].Controller;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  exports["default"] = Controller.extend({

    ///////////////////////////////////////
    ////////  Computed Properties  ////////
    ///////////////////////////////////////

    navigationOptions: computed("model.list", function () {
      var list = get(this, "model.list");
      var listName = get(list, "name");

      var title = "List";

      if (!this.tools.isEmpty(listName)) {
        title = listName;
      }

      return {
        type: "list",
        title: title,
        left: {
          icon: "back",
          label: "Videos",
          actionName: "goTo",
          actionParameter: "videos"
        }
      };
    }),

    toolbarOptions: computed("model.list", function () {

      var list = get(this, "model.list");
      var listName = get(list, "name");
      var listId = get(list, "id");

      var title = "List";

      if (!this.tools.isEmpty(listName)) {
        title = listName;
      }

      return {
        buttons: [{
          icon: "search",
          actionName: "openSearch",
          actionParameter: ["list", title, listId]
        }]
      };
    })
  });
});