define('merlin/routes/login', ['exports', 'ember'], function (exports, _ember) {
	var Route = _ember['default'].Route;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	exports['default'] = Route.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		authenticator: inject.service(),

		///////////////////////////
		////////  Methods  ////////
		///////////////////////////

		beforeModel: function beforeModel() {
			var _this = this;

			var session = get(this, 'session');
			if (get(session, 'isAuthenticated')) {
				this.transitionTo('/videos')['catch'](function (error) {
					if (error.message === "TransitionAborted" || error.name === "TransitionAborted") {
						return true;
					} else {
						_this.errorHandler.throwIt(error);
					}
				});
			} else {
				return true;
			}
		},

		///////////////////////////
		////////  Actions  ////////
		///////////////////////////

		actions: {
			error: function error(_error) {
				return this.errorHandler.log(_error);
			}
		}
	});
});