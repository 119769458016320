define('merlin/controllers/trial', ['exports', 'ember', 'merlin/config/environment', 'merlin/validations/trial'], function (exports, _ember, _merlinConfigEnvironment, _merlinValidationsTrial) {
  var Controller = _ember['default'].Controller;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Controller.extend({

    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    authenticator: inject.service(),
    firebaseWrapper: inject.service(),
    ajax: inject.service(),

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    isLoading: false,

    TrialValidations: _merlinValidationsTrial['default'],

    signupFormOptions: {
      fields: [{
        id: "nameOrganization",
        label: "ORGANIZATION NAME",
        focus: true
      }, {
        id: "organizationSize",
        label: "ORGANIZATION SIZE",
        type: "radio",
        options: [{
          label: "Just Me",
          value: "1"
        }, {
          label: "1-10 Athletes",
          value: "1-10"
        }, {
          label: "10-25 Athletes",
          value: "10-25"
        }, {
          label: "25+ Athletes",
          value: "25+"
        }]
      }, {
        id: "sport",
        label: "YOUR SPORT"
      }, {
        id: "nameFirst",
        label: "FIRST NAME"
      }, {
        id: "nameLast",
        label: "LAST NAME"
      }, {
        id: "email",
        label: "YOUR EMAIL ADDRESS",
        autocapitalize: "off",
        spellcheck: false,
        autocorrect: "off"
      }, {
        id: "password",
        label: "PASSWORD",
        type: "password",
        autocapitalize: "off",
        spellcheck: false,
        autocorrect: "off"
      }, {
        id: "confirmPassword",
        label: "CONFIRM PASSWORD",
        type: "password",
        autocapitalize: "off",
        spellcheck: false,
        autocorrect: "off"
      }],

      data: {
        nameOrganization: "",
        organizationSize: "",
        sport: "",
        nameFirst: "",
        nameLast: "",
        email: "",
        password: "",
        confirmPassword: ""
      },

      buttons: [{
        id: "signup",
        label: "Start My Free Trial"
      }],

      serverErrors: {}
    },

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    trySubmission: function trySubmission(result) {
      var _this = this;

      var data = {
        orgName: get(result, 'nameOrganization'),
        size: get(result, 'organizationSize'),
        sport: get(result, 'sport'),
        firstName: get(result, 'nameFirst'),
        lastName: get(result, 'nameLast'),
        email: get(result, 'email').toLowerCase(),
        password: get(result, 'password')
      };

      // Activate the loading animation.
      set(this, "isLoading", true);

      get(this, 'ajax').request(_merlinConfigEnvironment['default'].falconer + "account/signup", {
        method: 'POST',
        data: data
      }).then(function () {
        // Proceed to success.
        _this.transitionToRoute('trial_success');
      }).then(function () {
        set(_this, "isLoading", false);
      })['catch'](function (error) {
        _this.errorHandler.throwIt(error);
      });
    },

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      submittedSignup: function submittedSignup() {
        this.trySubmission(arguments[1]);
      }
    }

  });
});