define('merlin/routes/videos/list/video', ['exports', 'ember', 'merlin/routes/videos/inbox/video'], function (exports, _ember, _merlinRoutesVideosInboxVideo) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  exports['default'] = _merlinRoutesVideosInboxVideo['default'].extend({

    /////////////////////////////////////
    ////////  Lifecycle Methods  ////////
    /////////////////////////////////////

    afterModel: function afterModel() {
      set(this, 'list', this.modelFor('videos.list').list);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var videoController = this.controllerFor('videos.list.video');
      var list = get(this, "list");
      set(videoController, "list", list);
    }
  });
});