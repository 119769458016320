define('merlin/controllers/sso', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({

		authenticator: _ember['default'].inject.service(),

		////////////////////////////////////
		////////  Query Parameters  ////////
		////////////////////////////////////

		queryParams: ['token', 'error'],

		///////////////////////////
		////////  Actions  ////////
		///////////////////////////

		actions: {}
	});
});