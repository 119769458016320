define("merlin/routes/videos/search", ["exports", "ember"], function (exports, _ember) {
  var Route = _ember["default"].Route;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Route.extend({

    /////////////////////////////////////
    ////////  Lifecycle Methods  ////////
    /////////////////////////////////////

    beforeModel: function beforeModel(transition) {
      set(this, "filter", transition.queryParams.filter);
      set(this, "list_id", transition.queryParams.list_id);
    },

    model: function model() {
      var _this = this;

      var filter = get(this, "filter");

      if (filter !== "list") {
        var _ret = (function () {
          var pid = get(_this, "session.content.pid");

          var MODEL_NAMES = {
            _default: "person-all-video",
            inbox: "person-inbox",
            todo: "person-todo",
            favorites: "person-favorite",
            captures: "person-capture",
            reviews: "person-review",
            shared: "person-shared-with-me"
          };

          var model = MODEL_NAMES[filter] || MODEL_NAMES["_default"];

          return {
            v: _this.store.findRecord(model, pid).then(function (list) {
              return list.get("videos");
            }).then(function (videos) {
              return videos.filterBy('inCloud', true);
            })["catch"](function (error) {
              var organization = get(_this, "session.content.organization");
              var endpoint = "organizations/" + organization + "/person_all_videos";
              if (_this.errorHandler.isNoRecordError(error, pid, endpoint, true)) {
                return [];
              } else {
                _this.errorHandler.throwIt(error);
              }
            })
          };
        })();

        if (typeof _ret === "object") return _ret.v;
      } else {
        var lid = get(this, "list_id");
        return this.store.findRecord("list", lid).then(function (list) {
          return list.get("videos");
        })["catch"](function (error) {
          _this.errorHandler.throwIt(error);
        });
      }
    }
  });
});