define('merlin/validations/trial', ['exports', 'ember-changeset-validations/validators'], function (exports, _emberChangesetValidationsValidators) {
  exports['default'] = {
    nameOrganization: [(0, _emberChangesetValidationsValidators.validatePresence)({
      presence: true,
      message: "Organization name can’t be blank."
    })],
    organizationSize: [(0, _emberChangesetValidationsValidators.validateInclusion)({
      list: ['1', '1-10', '10-25', '25+'],
      message: "Please pick an organization size."
    })],
    sport: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    nameFirst: [(0, _emberChangesetValidationsValidators.validatePresence)({
      presence: true,
      message: "First name can’t be blank."
    })],
    nameLast: [(0, _emberChangesetValidationsValidators.validatePresence)({
      presence: true,
      message: "Last name can’t be blank."
    })],
    email: (0, _emberChangesetValidationsValidators.validateFormat)({ type: 'email' }),
    password: [(0, _emberChangesetValidationsValidators.validateLength)({ min: 6 })],
    confirmPassword: (0, _emberChangesetValidationsValidators.validateConfirmation)({
      on: 'password',
      message: "Doesn’t match Password."
    })
  };
});