define('merlin/routes/create-account', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    queryParams: {
      orgName: { refreshModel: true },
      firstName: { refreshModel: true },
      lastName: { refreshModel: true },
      email: { refreshModel: true },
      customer_id: { refreshModel: true }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setup();
    }
  });
});