define('merlin/routes/application', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
	var Route = _ember['default'].Route;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	exports['default'] = Route.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		authenticator: inject.service(),
		firebaseApp: inject.service(),
		sessionManager: inject.service(),

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		wasAuthenticated: false,

		/////////////////////////////////////
		////////  Lifecycle Methods  ////////
		/////////////////////////////////////

		// init() {
		// 	this._super(...arguments);
		// 	get(this,'firebaseApp').auth().onAuthStateChanged((user) => {
		// 		if(user === null && get(this,"wasAuthenticated")) {
		//       set(this,"wasAuthenticated",false);
		// 			get(this,"sessionManager").unload(/*true*/);
		// 			const state = get(this,"session.stateMachine.currentStateName");
		// 			if(state === "authenticated") {
		// 				get(this,"authenticator").safelyCloseSession()
		// 				.then(() => {
		//           console.log('sent to login #1');
		// 				  this.transitionTo('login');
		// 				})
		// 				.catch((error) => {
		// 				  this.errorHandler.throwIt(error);
		// 				});
		// 			} else {
		//         console.log('sent to login #2');
		// 				this.transitionTo('login');
		// 			}
		// 		} else {
		//       set(this,"wasAuthenticated",true);
		//     }
		// 	});
		// },

		// beforeModel() {
		// 	this._super(...arguments);
		// 	if (Modernizr.localstorage && Modernizr.sessionstorage) {
		// 		return true;
		//   } else {
		//   	this.transitionTo('issues');
		//   }
		// },

		captureMessageOnActivate: _ember['default'].on('activate', function () {
			get(this, 'raven').callRaven('setTagsContext', { environment: _merlinConfigEnvironment['default'].environment });
		}),

		///////////////////////////
		////////  Actions  ////////
		///////////////////////////

		actions: {
			accessDenied: function accessDenied() {
				var message = "<p>You must be logged in to see this page.</p>";
				this.EventBus.publish("sendNotification", message);
				this.transitionTo('login');
			}
		}
	});
});