define("merlin/routes/videos/manage-lists", ["exports", "ember"], function (exports, _ember) {
  var Route = _ember["default"].Route;
  var inject = _ember["default"].inject;
  var get = _ember["default"].get;
  exports["default"] = Route.extend({

    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    authenticator: inject.service(),
    sessionManager: inject.service(),

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    model: function model() {
      var _this = this;

      var authenticator = get(this, "authenticator");
      var pid = get(this, "session.content.pid");
      var organization = this.get("session.content.organization");
      // return this.modelFor("videos").lists;
      return this.store.findRecord('person_list', pid).then(function (person_list) {
        // try {
        // 	console.log(get(person_list,"person_list_lists"));
        // } catch(error) {
        // 	console.log(error);
        // }
        return get(person_list, "person_list_lists");
      })["catch"](function (error) {
        var endpoint = "organizations/" + organization + "/person_lists";
        if (_this.errorHandler.isNoRecordError(error, pid, endpoint)) {
          return [];
        } else {
          get(_this, "sessionManager").unload();
          return authenticator.safelyCloseSession().then(function () {
            return _this.transitionTo('login');
          })["catch"](function (error) {
            _this.errorHandler.throwIt(error);
          });
        }
      });
    },

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});