define('merlin/components/control-segment', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		tagName: 'li',
		classNames: ['control-segmented'],
		classNameBindings: ['isActive'],

		click: function click() {
			this.sendAction('clickedSegment', this.get('key'));
		}
	});
});