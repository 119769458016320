define("merlin/controllers/settings/account", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Controller.extend({
		navigationOptions: {
			type: "detail",
			title: "Account",
			left: {
				icon: "back",
				label: "Settings",
				actionName: "goTo",
				actionParameter: "settings",
				mobileOnly: true
			}
		}
	});
});