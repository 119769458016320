define('merlin/components/empty-state', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    classNames: ['container-emptyState'],
    classNameBindings: ['theme', 'interactive'],

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    interactive: false,

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    click: function click() {
      var onClickedEmptyStateButton = get(this, "onClickedEmptyStateButton");
      if (this.tools.isFunction(onClickedEmptyStateButton)) {
        get(this, "onClickedEmptyStateButton")();
      }
    }
  });
});