define('merlin/routes/coach', ['exports', 'ember'], function (exports, _ember) {
	var Route = _ember['default'].Route;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	exports['default'] = Route.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		authorizer: inject.service(),

		///////////////////////////
		////////  Methods  ////////
		///////////////////////////

		beforeModel: function beforeModel() {
			var _this = this;

			this._super.apply(this, arguments);
			var authorizer = get(this, 'authorizer');
			var uid = get(this, 'session.content.uid');

			authorizer.getTosState(uid).then(function (tosState) {
				if (tosState) {
					return authorizer.getRole(uid);
				} else {
					_this.transitionTo('/terms');
				}
			}).then(function (role) {
				if (role === "coach") {
					return true;
				} else {
					_this.transitionTo('login');
				}
			})['catch'](function (error) {
				_this.errorHandler.throwIt(error);
			});
		},

		actions: {
			error: function error(_error) {
				return this.errorHandler.log(_error);
			}
		}
	});
});