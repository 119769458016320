define("merlin/components/add-to-list", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var get = _ember["default"].get;
  var A = _ember["default"].A;

  /**
   * Component used to manage adding videos to lists.
   *
   * @class add-to-list
   */

  exports["default"] = Component.extend({

    /**********************
     * Default Properties *
     **********************/

    /**
     * An array of list models.
     *
     * @property lists
     * @type {Array}
     * @default []
     */
    lists: [],

    /**
     * An array of selected lists to add the video to.
     *
     * @property listManifest
     * @type {Array}
     * @default []
     */
    listManifest: A([]),

    /***********
     * Actions *
     ***********/

    actions: {

      /**
       * Adds or removes a list from the list manifest.
       *
       * @method manageListInManifest
       * @param {model} list A list model.
       */
      manageListInManifest: function manageListInManifest(list) {
        var listManifest = get(this, "listManifest");

        if (listManifest.contains(list)) {
          listManifest.removeObject(list);
        } else {
          listManifest.addObject(list);
        }

        get(this, "onUpdatedManifest")(listManifest);
      }
    }
  });
});