define('merlin/controllers/videos/manage-lists', ['exports', 'ember', 'merlin/validations/list'], function (exports, _ember, _merlinValidationsList) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Controller = _ember['default'].Controller;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Controller.extend({

    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    firebaseApp: _ember['default'].inject.service(),
    tools: inject.service(),

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    addListFormData: {
      name: ""
    },

    addListFormFields: [{
      id: "name",
      label: "LIST NAME",
      focus: true,
      autocapitalize: "off",
      spellcheck: false,
      autocorrect: "off"
    }],

    addListFormButtons: [{
      id: "done",
      label: "Done"
    }],

    addListNavigationOptions: {
      type: "modal",
      title: "Add New List",
      left: {
        label: "Cancel",
        actionName: "closeModal"
      }
    },

    // right: {
    //   label: "Done",
    //   actionName: "clickedDone",
    //   disabled: true,
    // }
    navigationOptions: {
      type: "modal",
      title: "Manage Lists",
      left: {
        label: "Done",
        actionName: "goTo",
        actionParameter: "videos"
      }
    },

    toolbarOptions: {
      buttons: [{
        icon: "add",
        actionName: "clickedAddListButton"
      }]
    },

    trySubmit: false,

    ListValidations: _merlinValidationsList['default'],

    showModal: false,

    /////////////////////////////////////
    ////////  Lifecycle Methods  ////////
    /////////////////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      this.EventBus.subscribe('clickedAddListButton', this, 'openModal');
    },

    willDestroyElement: function willDestroyElement() {
      this.get('EventBus').unsubscribe('parentCalling');
    },

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    addListToPersonList: function addListToPersonList(person_list, list) {
      var ref = person_list.ref();

      return ref.update(_defineProperty({}, get(list, "id"), {
        name: get(list, "name"),
        type: get(list, "type"),
        notifications: 0
      }));
      // .then(() => {
      //   return person_list.reload();
      // });
    },

    closeModal: function closeModal() {
      this.set("showModal", false);
    },

    openModal: function openModal() {
      this.set("showModal", true);
      set(this, "addListFormData.name", "");
    },

    clickedDone: function clickedDone() {
      set(this, "trySubmit", true);
    },

    submitNewList: function submitNewList(data) {
      var _this = this;

      var pid = get(this, "session.content.pid");

      if (data) {
        var _ret = (function () {
          // Need to check if list exists
          var newList = _this.store.createRecord("list", {
            name: get(data, "name"),
            owner: pid,
            permissions: "read-only",
            type: "custom"
          });

          var newPersonListList = {
            name: get(data, "name"),
            notifications: 0,
            type: "owner"
          };

          var personList = null;

          return {
            v: newList.save().then(function (list) {
              newPersonListList.id = get(list, "id");
              return _this.store.findRecord('person_list', pid);
            }).then(function (person_list) {
              personList = person_list;
              return _this.addListToPersonList(person_list, newPersonListList);
            }).then(function () {
              set(_this, "showModal", false);
              return personList.save();
            }).then(function () {
              return personList.reload();
            }).then(function () {
              _this.send('refresh');
            })['catch'](function (error) {
              var endpoint = 'organizations/' + get(_this, "session.content.organization") + '/person_lists';
              if (_this.errorHandler.isNoRecordError(error, pid, endpoint)) {
                var _ret2 = (function () {
                  var newPersonList = _this.store.createRecord('person_list', {
                    id: pid,
                    lists: []
                  });

                  return {
                    v: _this.addListToPersonList(newPersonList, newPersonListList).then(function () {
                      set(_this, "showModal", false);
                      // this.send('refresh');
                      return newPersonList.save();
                    }).then(function () {
                      return newPersonList.reload();
                    }).then(function () {
                      _this.send('refresh');
                    })['catch'](function (error) {
                      _this.errorHandler.throwIt(error);
                    })
                  };
                })();

                if (typeof _ret2 === 'object') return _ret2.v;
              } else {
                _this.errorHandler.throwIt(error);
              }
            })
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      } else {
        set(this, "addListNavigationOptions.right.disabled", true);
      }
    },

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      closeDialogue: function closeDialogue() {
        this.closeModal();
      },

      parseAction: function parseAction(actionName) {
        var tools = get(this, "tools");
        if (!tools.isEmpty(actionName)) {
          var action = this[actionName];
          if (tools.isFunction(action)) {
            for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
              args[_key - 1] = arguments[_key];
            }

            this[actionName].apply(this, args);
          } else {
            throw new Error('There is no function "' + actionName + '" in the manage-lists controller scope.');
          }
        } else {
          throw new Error('No actionName provided to parseAction()');
        }
      },

      removeList: function removeList(person_list_list) {
        var _this2 = this;

        var message = "Do you want to delete this list? Deleting a list is a permanent action.";

        var type = get(person_list_list, "type");
        if (type === 'subscribed') {
          message = "Do you want to ubsubscribe from this list?";
        }

        //@TODO: Doesn’t actullly delete lists.
        if (window.confirm(message)) {
          var _ret3 = (function () {
            var organization = get(_this2, "session.content.organization");
            var pid = get(_this2, "session.content.pid");
            var lid = get(person_list_list, "id");
            var personList = _this2.store.peekRecord('person_list', pid);

            var url = 'organizations/' + organization + '/person_lists/' + pid + '/' + lid;
            var ref = get(_this2, "firebaseApp").database().ref(url);

            return {
              v: ref.remove().then(function () {
                return personList.save();
              }).then(function () {
                if (!get(personList, "isDeleted")) {
                  personList.reload();
                }
              })['catch'](function (error) {
                _this2.errorHandler.throwIt(error);
              })
            };
          })();

          if (typeof _ret3 === 'object') return _ret3.v;
        }
      },

      enableDoneButton: function enableDoneButton() {
        set(this, "addListNavigationOptions.right.disabled", false);
      },

      disableDoneButton: function disableDoneButton() {
        set(this, "addListNavigationOptions.right.disabled", true);
      }
    }
  });
});