define('merlin/components/scroll-view', ['exports', 'ember'], function (exports, _ember) {
	// import ResizeAware from 'ember-resize/mixins/resize-aware';
	// import { PerfectScrollbarMixin } from 'ember-perfect-scrollbar';

	var Component = _ember['default'].Component;
	var get = _ember['default'].get;
	var computed = _ember['default'].computed;
	exports['default'] = Component.extend( /*PerfectScrollbarMixin,*/{

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		classes: "",
		resize: true,

		///////////////////////////////////////
		////////  Component Defintion  ////////
		///////////////////////////////////////

		classNames: ['container-scrollView'],
		classNameBindings: ['dividerClass', 'classes'],
		attributeBindings: ['style'],

		///////////////////////////////////////
		////////  Computed Properties  ////////
		///////////////////////////////////////

		dividerClass: computed('divider', function () {
			var divider = get(this, 'divider');

			if (divider) {
				return 'divider-' + divider;
			} else {
				return "";
			}
		})
	});
});