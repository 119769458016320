define("merlin/controllers/videos", ["exports", "ember"], function (exports, _ember) {
	var Controller = _ember["default"].Controller;
	var inject = _ember["default"].inject;
	var computed = _ember["default"].computed;
	var get = _ember["default"].get;
	var set = _ember["default"].set;
	var A = _ember["default"].A;
	var RSVP = _ember["default"].RSVP;

	/**
  * Controller for the videos route.
  *
  * @class videos-controller
  */

	exports["default"] = Controller.extend({

		/**************
   * Services *
   **************/

		tools: inject.service(),
		currentRoute: inject.service(),
		firebaseApp: inject.service(),

		/************************
   * Default Properties *
   ************************/

		/**
   * Array of lists to add the current video to.
   *
   * @property addToListManifest
   * @type {Array}
   * @default []
   */
		addToListManifest: [],

		addToListNavigationOptions: {
			type: "modal",
			title: "Add to a List",
			left: {
				label: "Cancel",
				actionName: "cancelAddToList"
			},
			right: {
				label: "Done",
				actionName: "addVideoToLists"
			}
		},

		isEmpty: false,

		showAddToListDialogue: false,

		/*************************
   * Computed Properties *
   *************************/

		lists: computed('model.lists', function () {
			return get(this, 'model.lists');
		}),

		/*************
   * Methods *
   *************/

		addVideoToLists: function addVideoToLists() {
			var _this = this;

			var manifest = get(this, "addToListManifest");

			if (get(manifest, "length") === 0) {
				this.closeDialogue();
			}

			var vid = get(this, "currentRoute.currentDynamicSegment");

			this.store.findRecord('video', vid).then(function (video) {
				var promises = A([]);

				manifest.forEach(function (list) {
					var promise = _this._addVideoToList(video, list);
					promises.addObject(promise);
				});

				return RSVP.all(promises);
			})["catch"](function (error) {
				_this.errorHandler.throwIt(error);
			});
		},

		_addVideoToList: function _addVideoToList(video, list) {
			return get(list, "videos").then(function (videos) {
				var newData = {};

				if (!videos.contains(video)) {
					newData[get(video, "id")] = true;
				}
				var ref = list.ref();
				return ref.update(newData);
			});
		},

		_removeVideoFromList: function _removeVideoFromList(vid, lid) {
			var _this2 = this;

			var organization = get(this, "session.content.organization");
			var pid = get(this, "session.content.pid");
			var id = lid;

			var listModel = "list";
			var endpointModel = "list";

			if (lid === 'inbox') {
				listModel = "person_inbox";
				endpointModel = listModel;
				id = pid;
			} else if (lid === 'todos') {
				listModel = "person_todo";
				endpointModel = listModel;
				id = pid;
			} else if (lid === 'favorites') {
				listModel = "person_favorite";
				endpointModel = "person_favorites";
				id = pid;
			}

			var url = "organizations/" + organization + "/" + endpointModel + "/" + pid + "/" + vid;
			var ref = get(this, "firebaseApp").database().ref(url);

			var list = null;

			return this.store.findRecord(listModel, id).then(function (result) {
				list = result;
				return ref.remove();
			}).then(function () {
				return list.save();
			}).then(function () {
				return list.reload();
			}).then(function () {
				return _this2.transitionToRoute("videos." + lid);
			})["catch"](function (error) {
				_this2.errorHandler.throwIt(error);
			});
		},

		cancelAddToList: function cancelAddToList() {
			set(this, "addToListManifest", []);
			this.closeDialogue();
		},

		goTo: function goTo(target) {
			if (!this.tools.isEmpty(target)) {
				if (this.tools.isArray(target)) {
					this.transitionToRoute(target[0], target[1]);
				} else {
					this.transitionToRoute(target);
				}
			} else {
				throw new Error('No target provided to goTo()');
			}
		},

		openSearch: function openSearch() {
			if (this.tools.isNotEmpty(arguments[0])) {
				if (this.tools.isArray(arguments[0])) {
					this.transitionToRoute('videos.search', {
						queryParams: {
							filter: arguments[0][0],
							list_name: arguments[0][1],
							list_id: arguments[0][2]
						}
					});
				} else {
					this.transitionToRoute('videos.search', {
						queryParams: { filter: arguments[0] }
					});
				}
			} else {
				this.transitionToRoute('videos.search', {
					queryParams: {
						filter: "",
						list_name: "",
						list_id: ""
					}
				});
			}
		},

		openManageLists: function openManageLists() {
			console.log('opening manage lists');
		},

		clickedAddListButton: function clickedAddListButton() {
			this.EventBus.publish('clickedAddListButton');
		},

		clickedAddToList: function clickedAddToList() {
			set(this, "showAddToListDialogue", true);
		},

		clickedRemoveFromList: function clickedRemoveFromList() {
			var _this3 = this;

			var vid = get(this, "currentRoute.currentDynamicSegment");
			var lid = get(this, "currentRoute.routeHierarchy")[1];

			this._removeVideoFromList(vid, lid)["catch"](function (error) {
				_this3.errorHandler.throwIt(error);
			});
		},

		closeDialogue: function closeDialogue() {
			set(this, "showAddToListDialogue", false);
		},

		/*************
   * Actions *
   *************/

		actions: {

			parseAction: function parseAction(actionName) {
				var tools = get(this, "tools");
				if (!tools.isEmpty(actionName)) {
					var action = this[actionName];
					if (tools.isFunction(action)) {
						for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
							args[_key - 1] = arguments[_key];
						}

						this[actionName].apply(this, args);
					} else {
						throw new Error("There is no function \"" + actionName + "\" in the videos controller scope.");
					}
				} else {
					throw new Error("No actionName provided to parseAction()");
				}
			},

			clickedCloseDialogue: function clickedCloseDialogue() {
				this.closeDialogue();
			},

			updateAddToListManifest: function updateAddToListManifest(manifest) {
				set(this, "addToListManifest", manifest);
			}
		}
	});
});