define("merlin/components/my-notification", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    classNames: ["container-modal-notification-outer"],
    classNameBindings: ["isActive"],

    actions: {
      closeModal: function closeModal() {
        this.get("onCloseModal")();
      }
    }
  });
});