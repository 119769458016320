define('merlin/controllers/issues', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
	exports['default'] = _ember['default'].Controller.extend({

		// queryParams: ['issueType'],

		issueType: true,

		// issue: Ember.computed('issueType', function() {
		// 	// if (this.get('issueType') === 'private-browsing') {
		// 	// 	return this.get('privateBrowsingContent');
		// 	// } else {
		// 	// 	return {};
		// 	// }
		// 	return this.get('privateBrowsingContent');
		// }),

		// privateBrowsingContent: {
		// 	title: "Private Browsing Detected",
		// 	message: "Unfortunately APEX does not work while using Private Browsing. If you can turn if off, great! However, we understand privacy is important—so we provide another option: If you are on an iPhone or iPad, you can view your videos using the APEX iOS app.",
		// 	returnToSource: false,
		// 	keepParams: false,
		// 	sendToAppStore: true,
		// 	actionText: "Get APEX on the App Store."
		// },

		privateBrowsingIssue: _ember['default'].computed('', function () {
			if (Modernizr.localstorage && Modernizr.sessionstorage) {
				return false;
			} else {
				return true;
			}
		}),

		appStoreLink: _ember['default'].computed('', function () {
			return _merlinConfigEnvironment['default'].appStoreLink;
		})
	});
});