define('merlin/components/contact-sales-button', ['exports', 'ember', 'merlin/emails/sales-inquiry'], function (exports, _ember, _merlinEmailsSalesInquiry) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    tagName: "",

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    to: _merlinEmailsSalesInquiry['default'].to,
    subject: _merlinEmailsSalesInquiry['default'].subject,
    body: _merlinEmailsSalesInquiry['default'].body,
    info: "",

    ///////////////////////////////////////
    ////////  Computed Properties  ////////
    ///////////////////////////////////////

    emailTo: computed("to", function () {
      // return this.encode(get(this,"to"));
      return get(this, "to");
    }),

    emailSubject: computed("subject", function () {
      return this.encode(get(this, "subject"));
    }),

    emailBody: computed("body", function () {
      var body = get(this, "body") + '\n\n' + get(this, "info");
      return this.encode(body);
    }),

    emailLink: computed("emailTo", "emailSubject", "emailBody", function () {
      var emailTo = get(this, "emailTo");
      var emailSubject = get(this, "emailSubject");
      var emailBody = get(this, "emailBody");
      return 'mailto:' + emailTo + '?Subject=' + emailSubject + '&Body=' + emailBody;
    }),

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    encode: function encode(str) {
      return encodeURIComponent(str);
    }
  });
});