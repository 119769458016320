define('merlin/adapters/organization-person', ['exports', 'ember', 'merlin/adapters/application'], function (exports, _ember, _merlinAdaptersApplication) {
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  exports['default'] = _merlinAdaptersApplication['default'].extend({
    session: inject.service(),

    pathForType: function pathForType() {
      var organization = get(this, 'session.content.organization');
      return 'organizations/' + organization + '/people';
    }
  });
});