define("merlin/validations/createAccount", ["exports", "ember-changeset-validations/validators"], function (exports, _emberChangesetValidationsValidators) {
  exports["default"] = {
    nameOrganization: [(0, _emberChangesetValidationsValidators.validatePresence)({
      presence: true,
      message: "Organization name can’t be blank."
    })],
    nameFirst: [(0, _emberChangesetValidationsValidators.validatePresence)({
      presence: true,
      message: "First name can’t be blank."
    })],
    nameLast: [(0, _emberChangesetValidationsValidators.validatePresence)({
      presence: true,
      message: "Last name can’t be blank."
    })],
    email: (0, _emberChangesetValidationsValidators.validateFormat)({ type: 'email' }),
    password: [(0, _emberChangesetValidationsValidators.validateLength)({ min: 6 })],
    confirmPassword: (0, _emberChangesetValidationsValidators.validateConfirmation)({
      on: 'password',
      message: "Doesn’t match Password."
    })
  };
});