define('merlin/app', ['exports', 'ember', 'merlin/resolver', 'ember-load-initializers', 'merlin/config/environment'], function (exports, _ember, _merlinResolver, _emberLoadInitializers, _merlinConfigEnvironment) {

  var App = undefined;

  App = _ember['default'].Application.extend({
    modulePrefix: _merlinConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _merlinConfigEnvironment['default'].podModulePrefix,
    Resolver: _merlinResolver['default']
  });

  _ember['default'].onerror = function (error) {
    console.error('Ember.onerror handler', error.message, error.stack, error.code);
  };

  window.onerror = function (message) {
    // console.log(message);
    // throw new Error(message, source, lineno, colno, error);
    if (message === "Error: This browser is not supported.") {
      return true;
    }
  };

  _ember['default'].RSVP.on('error', function (error) {
    if (error.name !== "TransitionAborted") {
      console.error('Ember.RSVP error handler', error);
    } else {
      console.warn('Ignored TransitionAborted error.');
    }
  });

  _ember['default'].Logger.error = function (message, cause, stack) {
    if (message) {
      console.error(message);
    } else {
      console.log('No message provided.');
    }

    if (cause) {
      console.error(cause);
    } else {
      console.log('No cause provided.');
    }

    if (stack) {
      console.error(stack);
    } else {
      console.log('No stack provided.');
    }
  };

  (0, _emberLoadInitializers['default'])(App, _merlinConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});