define('merlin/components/search-list', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({

    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    searchTools: inject.service(),

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    classNames: ['container-searchList'],

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    startTitle: "Find Videos",
    startMessage: "Search for tags or people.",
    noResultsTitle: "No Matches",
    noResultsMessage: "There are no videos associated with your search terms.",

    showCancel: true,

    ///////////////////////////////////////
    ////////  Computed Properties  ////////
    ///////////////////////////////////////

    emptyTitle: computed("startTitle", function () {
      return get(this, "startTitle");
    }),

    emptyMessage: computed("startMessage", function () {
      return get(this, "startMessage");
    }),

    sourceList: computed("items.[]", function () {
      var items = get(this, "items");
      if (this._itemsNotEmpty(items)) {
        return get(this, "searchTools").convertList(items);
      } else {
        return false;
      }
    }),

    videoItemList: _ember['default'].computed('items.[]', function () {
      return get(this, "items");
    }),

    isStart: true,

    isNotEmpty: computed("videoItemList", function () {
      var items = get(this, "videoItemList");
      return this._itemsNotEmpty(items);
    }),

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    _itemsNotEmpty: function _itemsNotEmpty(items) {
      return this.tools.isNotEmpty(items) && get(items, "length") > 0;
    },

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      onHandleResults: function onHandleResults(results) {

        set(this, "isStart", false);

        if (results) {
          var convertedResults = get(this, 'searchTools').convertResults(results);
          // console.log(convertedResults);
          set(this, 'videoItemList', convertedResults);
        } else {
          set(this, "emptyTitle", get(this, "noResultsTitle"));
          set(this, "emptyMessage", get(this, "noResultsMessage"));
          set(this, 'videoItemList', get(this, "items"));
        }
      },

      cancelSearch: function cancelSearch() {
        get(this, "onCancelSearch")();
      }
    }
  });
});