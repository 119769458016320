define("merlin/components/manage-lists", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var get = _ember["default"].get;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend({

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    tagName: "",
    // classNames: ['container-list-tableview-edit'],

    ///////////////////////////////////////
    ////////  Computed Properties  ////////
    ///////////////////////////////////////

    lists: computed("data.[]", function () {
      return get(this, "data");
    }),

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      clickedRemoveListButton: function clickedRemoveListButton(list) {
        get(this, "onRemoveList")(list);
      }
    }
  });
});