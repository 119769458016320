define('merlin/adapters/video', ['exports', 'ember', 'emberfire/adapters/firebase'], function (exports, _ember, _emberfireAdaptersFirebase) {
	exports['default'] = _emberfireAdaptersFirebase['default'].extend({

		session: _ember['default'].inject.service(),

		pathForType: function pathForType(type) {
			var underscored = _ember['default'].String.underscore(type);
			var name = _ember['default'].String.pluralize(underscored);
			return "organizations/" + this.get('session.content.organization') + "/" + name;
		}
	});
});