define('merlin/components/detail-view', ['exports', 'ember'], function (exports, _ember) {
	var Component = _ember['default'].Component;
	var get = _ember['default'].get;
	var set = _ember['default'].set;
	exports['default'] = Component.extend({

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
			var data = get(this, 'data');
			if (data) {
				set(this, 'currentData', data);
			}
		},

		/***********
   * Methods *
   ***********/

		handleDetailAction: function handleDetailAction(actionName) {
			var detailAction = get(this, actionName);
			if (this.tools.isFunction(detailAction)) {
				for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
					args[_key - 1] = arguments[_key];
				}

				get(this, actionName).apply(undefined, args);
			} else {
				throw new Error('There is no function "' + actionName + '" at the calling scope.');
			}
		},

		/***********
   * Actions *
   ***********/

		actions: {
			onDetailNavbarAction: function onDetailNavbarAction() {
				for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
					args[_key2] = arguments[_key2];
				}

				this.handleDetailAction.apply(this, ["detailNavbarAction"].concat(args));
			},

			onDetailToolbarAction: function onDetailToolbarAction() {
				for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
					args[_key3] = arguments[_key3];
				}

				this.handleDetailAction.apply(this, ["detailToolbarAction"].concat(args));
			}
		}
	});
});