define('merlin/controllers/index', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
	var Controller = _ember['default'].Controller;
	var computed = _ember['default'].computed;
	exports['default'] = Controller.extend({

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		queryParams: ['utm_source', 'utm_medium', 'utm_campaign'],

		///////////////////////////////////////
		////////  Computed Properties  ////////
		///////////////////////////////////////

		appStoreLink: computed('', function () {
			return _merlinConfigEnvironment['default'].appStoreLink;
		})
	});
});