define('merlin/helpers/changeset', ['exports', 'ember-changeset-validations/helpers/changeset'], function (exports, _emberChangesetValidationsHelpersChangeset) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberChangesetValidationsHelpersChangeset['default'];
    }
  });
  Object.defineProperty(exports, 'changeset', {
    enumerable: true,
    get: function get() {
      return _emberChangesetValidationsHelpersChangeset.changeset;
    }
  });
});