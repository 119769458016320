define('merlin/router', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;

  var Router = _ember['default'].Router.extend({
    location: _merlinConfigEnvironment['default'].locationType,
    rootURL: _merlinConfigEnvironment['default'].rootURL,
    metrics: inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },

    _trackPage: function _trackPage() {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var page = document.location.pathname;
        var title = _this.getWithDefault('currentRouteName', 'unknown');

        get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {
    this.route('about');
    this.authenticatedRoute('coach');
    this.route('confirm');

    this.route('error-test', function () {
      this.route('error-child');
    });

    this.route('index', { path: '/' });
    this.route('issues');
    this.route('login');
    this.route('notfound', { path: "*path" });
    this.route('privacy');

    this.authenticatedRoute('settings', function () {
      this.route('account');
      this.route('about');
      this.route('terms');
      this.route('privacy');
    });

    this.route('signup');
    this.route('sso');
    this.route('success');
    this.route('terms');
    this.route('terms-of-service');
    this.route('trial');
    this.route('trial_success');
    this.route('create_account');

    this.authenticatedRoute('videos', function () {
      this.route('list', { path: '/:list_id' }, function () {
        this.route('video', { path: '/:video_id' });
      });
      this.route('inbox', function () {
        this.route('video', { path: '/:video_id' });
      });
      this.route('todo', function () {
        this.route('video', { path: '/:video_id' });
      });
      this.route('favorites', function () {
        this.route('video', { path: '/:video_id' });
      });
      this.route('captures', function () {
        this.route('video', { path: '/:video_id' });
      });
      this.route('manage-lists');
      this.route('reviews', function () {
        this.route('video', { path: '/:video_id' });
        this.route('unwatched');
        this.route('watched');
      });
      this.route('search', function () {
        this.route('video', { path: '/:video_id' });
      });
      this.route('shared', function () {
        this.route('video', { path: '/:video_id' });
      });
      this.route('unwatched');
    });
    this.route('signup_success');
  });

  exports['default'] = Router;
});