define("merlin/controllers/videos/favorites/video", ["exports", "merlin/controllers/videos/inbox/video"], function (exports, _merlinControllersVideosInboxVideo) {
	exports["default"] = _merlinControllersVideosInboxVideo["default"].extend({
		navigationOptions: {
			type: "video",
			title: "Watch Video",
			left: {
				icon: "back",
				label: "Favorites",
				actionName: "goTo",
				actionParameter: "videos.favorites",
				mobileOnly: true
			}
		}
	});
});
// import Ember from 'ember';