define("merlin/components/video-lists", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  exports["default"] = Component.extend({

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    tagName: "",

    ///////////////////////////////////////
    ////////  Computed Properties  ////////
    ///////////////////////////////////////

    inboxCount: computed("lists.inbox.videos", function () {
      return this.getListLength("inbox");
    }),

    todoCount: computed("lists.todos.videos", function () {
      return this.getListLength("todos");
    }),

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    getListLength: function getListLength(listName) {
      var videos = get(this, "lists." + listName + ".videos");
      return get(videos, "length");
    },

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      clickedListsEmptyState: function clickedListsEmptyState() {
        get(this, "onClickedListsEmptyState")();
      }
    }
  });
});