define('merlin/components/bar-navigation', ['exports', 'ember'], function (exports, _ember) {
	var Component = _ember['default'].Component;
	var get = _ember['default'].get;
	var assign = _ember['default'].assign;
	var computed = _ember['default'].computed;
	exports['default'] = Component.extend({

		///////////////////////////////////////
		////////  Component Defintion  ////////
		///////////////////////////////////////

		classNames: ['bar-navigation'],
		classNameBindings: ['theme', 'flex', 'border'],

		/////////////////////////////////////
		////////  Lifecycle Methods  ////////
		/////////////////////////////////////

		init: function init() {
			this._super.apply(this, arguments);
			this.defaults = {
				border: true,
				type: null,
				title: null,
				// left: {
				// 	icon: null,
				// 	label: null,
				// 	actionName: null,
				// 	actionParameter: null,
				// 	mobileOnly: false,
				// 	disabled: false
				// },
				// right: {
				// 	icon: null,
				// 	label: null,
				// 	actionName: null,
				// 	actionParameter: null,
				// 	mobileOnly: false,
				// 	disabled: false
				// },
				left: null,
				right: null,
				theme: {
					background: "dark",
					buttonColor: "blue"
				},
				flex: true
			};
		},

		///////////////////////////////////////
		////////  Computed Properties  ////////
		///////////////////////////////////////

		border: computed('settings', function () {
			return get(this, 'settings').border;
		}),

		theme: computed('settings', function () {
			return get(this, 'settings').theme.background;
		}),

		flex: computed('settings', function () {
			return get(this, 'settings').flex;
		}),

		settings: computed('defaults', 'options', function () {
			assign(get(this, 'defaults'), get(this, 'options'));
			return get(this, 'defaults');
		}),

		hasLeftButton: computed("settings.left", function () {
			var settings = get(this, "settings.left");
			return this.tools.objectIsNotEmpty(settings);
		}),

		hasRightButton: computed("settings.right", function () {
			var settings = get(this, "settings.right");
			return this.tools.objectIsNotEmpty(settings);
		}),

		actions: {

			// Passes action up the chain

			clickedButton: function clickedButton(key) {
				if (key === "left" || key === "right") {
					var actionName = get(this, 'settings')[key].actionName;
					var actionParameter = get(this, 'settings')[key].actionParameter;

					var onNavbarAction = get(this, 'onNavbarAction');
					if (this.tools.isFunction(onNavbarAction)) {
						get(this, 'onNavbarAction')(actionName, actionParameter);
					} else {
						throw new Error('There is no function called "onNavbarAction" at this component’s parent’s scope.');
					}
				} else {
					throw new Error('Navbar button key must be \'left\' or \'right\' You provided: ' + key + '.');
				}
			}
		}
	});
});