define('merlin/initializers/navigation', ['exports', 'merlin/config/environment'], function (exports, _merlinConfigEnvironment) {
  exports.initialize = initialize;

  function initialize() /* application */{
    var application = arguments[1] || arguments[0];
    var uiNavigator = _merlinConfigEnvironment['default'].uiNavigator;

    uiNavigator = uiNavigator || {};

    var _ref = uiNavigator || [];

    var injectionFactories = _ref.injectionFactories;

    application.register('config:navigator', uiNavigator, { instantiate: false });
    if (injectionFactories.length > 0) {
      application.inject('service:navigator', 'uiNavigator', 'config:navigator');

      injectionFactories.forEach(function (factory) {
        application.inject(factory, 'navigator', 'service:navigator');
      });
    }
  }

  exports['default'] = {
    name: 'navigator',
    initialize: initialize
  };
});