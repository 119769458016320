define('merlin/controllers/videos/list/video', ['exports', 'ember', 'merlin/controllers/videos/inbox/video'], function (exports, _ember, _merlinControllersVideosInboxVideo) {
	var computed = _ember['default'].computed;
	var get = _ember['default'].get;
	exports['default'] = _merlinControllersVideosInboxVideo['default'].extend({

		///////////////////////////////////////
		////////  Computed Properties  ////////
		///////////////////////////////////////

		navigationOptions: computed("list", function () {
			var list = get(this, "list");
			var listName = get(list, "name");
			var listId = get(list, "id");

			var title = "List";

			if (!this.tools.isEmpty(listName)) {
				title = listName;
			}

			return {
				type: "video",
				title: "Watch Video",
				left: {
					icon: "back",
					label: title,
					actionName: "goTo",
					actionParameter: ['videos.list', listId],
					mobileOnly: true
				}
			};
		})
	});
});