define("merlin/routes/success", ["exports", "ember"], function (exports, _ember) {
	var Route = _ember["default"].Route;
	var get = _ember["default"].get;
	var set = _ember["default"].set;
	exports["default"] = Route.extend({

		model: function model() {
			var _this = this;

			var session = get(this, "session");
			var pid = get(session, "pid");

			if (this.tools.isEmpty(pid)) {
				var uid = get(session, "uid");

				return this.store.findRecord('user', uid).then(function (user) {
					var authorizations = get(user, 'authorizations');

					if (!_this.tools.isEmpty(authorizations) && get(authorizations, "length") > 0) {
						var authorization = authorizations.objectAt(0);
						var organization = authorization.id;
						set(session, "organization", organization);
						var _pid = get(authorization, "personID");
						return _this.store.findRecord('organization-person', _pid);
					} else {
						var error = new Error("User has no authorizations");
						error.code = "custom/user-no-authorizations";
						throw error;
					}
				})["catch"](function (error) {
					_this.errorHandler.throwIt(error);
				});
			} else {
				return this.store.findRecord('organization-person', pid)["catch"](function (error) {
					_this.errorHandler.throwIt(error);
				});
			}
		}
	});
});