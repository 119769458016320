define("merlin/components/form-field", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var inject = _ember["default"].inject;
  var $ = _ember["default"].$;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var guidFor = _ember["default"].guidFor;

  var id = 0;

  exports["default"] = Component.extend({

    tools: inject.service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      if (get(this, "focus")) {
        (function () {
          var inputId = get(_this, "inputId");
          _ember["default"].run.later(function () {
            // this.$(`#${inputId}`).focus();
            $("#" + inputId).focus();
          }, 0);
        })();
      }
    },

    classNames: ['container-input'],
    attributeBindings: ['autocapitalize', 'spellcheck', 'autocorrect'],
    classNameBindings: ['isError', 'hide:is-hidden'],

    inputClasses: computed("inputId", "classes", function () {
      var classes = get(this, "classes") || "";
      return "input-text " + classes;
    }),

    isError: computed("errors", function () {
      return get(this, "errors") !== undefined;
    }),

    inputId: computed(function () {
      var guid = guidFor(this);
      id++;
      return "input-" + guid + "-" + id;
    }),

    inputType: computed("type", function () {
      var type = get(this, "type");
      return get(this, "tools").isEmpty(type) ? "text" : type;
    }),

    placeholder: computed("inputPlaceholder", function () {
      var inputPlaceholder = get(this, "inputPlaceholder");
      return get(this, "tools").isEmpty(inputPlaceholder) ? "Type here." : inputPlaceholder;
    }),

    isHidden: computed("type", function () {
      return get(this, "type") === "hidden";
    }),

    actions: {
      focusedOut: function focusedOut() {
        get(this, "onFocusOut")();
      },

      changed: function changed() {
        var value = get(this, "inputValue");
        get(this, "onChange")(value);
      }
    }
  });
});