define("merlin/components/request-reset", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var inject = _ember["default"].inject;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({

    /************
     * Services *
     ************/

    firebaseWrapper: inject.service(),

    /**********************
     * Default Properties *
     **********************/

    requestIsLoading: true,
    requestFailed: false,

    /*********************
     * Lifecycle Methods *
     *********************/

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var isActive = get(this, "isActive");
      var email = get(this, "email");
      var emailIsPresent = !this.tools.isEmpty(email);

      if (isActive && emailIsPresent) {
        get(this, 'firebaseWrapper').sendPasswordResetEmail(email).then(function () {
          set(_this, "requestIsLoading", false);
        })["catch"](function (error) {
          if (error.code === "auth/invalid-email") {
            set(_this, "requestFailed", true);
          } else if (error.code === "auth/user-not-found") {
            set(_this, "requestFailed", true);
          } else {
            _this.errorHandler.throwIt(error);
          }
        });
      }
    },

    /***********
     * Actions *
     ***********/

    actions: {
      closeDialogue: function closeDialogue() {
        get(this, "onCloseDialogue")();
      },

      done: function done() {
        get(this, "onCloseDialogue")();
        get(this, "onDone")();
      }
    }
  });
});