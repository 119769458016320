define('merlin/services/firebase-wrapper', ['exports', 'ember', 'firebase'], function (exports, _ember, _firebase) {
	var Service = _ember['default'].Service;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	exports['default'] = Service.extend({

		session: inject.service(),
		firebaseApp: inject.service(),
		authenticator: inject.service(),

		sendPasswordResetEmail: function sendPasswordResetEmail(email) {
			var auth = get(this, 'firebaseApp').auth();
			return auth.sendPasswordResetEmail(email);
		},

		confirmPasswordReset: function confirmPasswordReset(code, passwordNew) {
			var auth = get(this, 'firebaseApp').auth();
			return auth.confirmPasswordReset(code, passwordNew);
		},

		changePassword: function changePassword(passwordOld, passwordNew) {

			var auth = get(this, 'firebaseApp').auth();
			var user = auth.currentUser;
			var credential = _firebase['default'].auth.EmailAuthProvider.credential(user.email, passwordOld);

			return user.reauthenticate(credential).then(function () {
				return user.updatePassword(passwordNew);
			});
		},

		createAccount: function createAccount(email, password) {
			var auth = get(this, 'firebaseApp').auth();

			return auth.createUserWithEmailAndPassword(email, password);
		}
	});
});