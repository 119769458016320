define('merlin/services/review-manager', ['exports', 'ember'], function (exports, _ember) {
	var Service = _ember['default'].Service;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	var RSVP = _ember['default'].RSVP;
	exports['default'] = Service.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		session: inject.service(),
		store: inject.service(),
		peopleManager: inject.service(),

		///////////////////////////
		////////  Methods  ////////
		///////////////////////////

		// Debug Helper Function
		addReviewWithID: function addReviewWithID(videoID) {
			var _this = this;

			return get(this, 'store').findRecord('video', videoID).then(function (video) {
				return _this.moveToUnwatched(video);
			});
		},

		getVideoWithID: function getVideoWithID(videoID) {
			return get(this, 'store').findRecord('video', videoID);
		},

		moveReview: function moveReview(video, reviewType) {
			var _this2 = this;

			if (!video) {
				throw new Error("No video was provided to moveReview().");
			}

			if (!reviewType) {
				throw new Error("No reviewType was provided to moveReview(). reviewType must be 'watched' or 'unwatched'.");
			}

			if (reviewType !== "watched" && reviewType !== "unwatched") {
				throw new Error("reviewType: " + reviewType + " was provided to moveReview(). reviewType must be 'watched' or 'unwatched'.");
			}

			var uid = this.get('session.content.uid');
			var pid = undefined;
			var reviewList = undefined;

			// 1) Get our person.
			return get(this, 'peopleManager').getPid(uid)
			// 2) Give pid our personId value.
			// 3) Get the desired review list.
			.then(function (personId) {
				pid = personId;
				return _this2._getList(reviewType, pid);
			})
			// 3a) If the list doesn’t exist, make a new one.
			['catch'](function (error) {
				if (_this2.errorHandler.isNoRecordError(error, pid, "athlete_" + reviewType + "_reviews")) {
					return _this2._createNewList(reviewType, pid);
				} else {
					throw new Error(error);
				}
			})
			// 4) Set reviewList to list
			// 5) Create a new video list item of the desired type.
			.then(function (list) {
				reviewList = list;
				return _this2.createNewVideoListItem(video, reviewType);
			})
			// 6) Add the video list item to the list.
			.then(function (newReview) {
				return _this2.addVideo(reviewList, newReview);
			})
			// 7) Get the other list of reviews.
			.then(function () {
				if (reviewType === "unwatched") {
					return _this2.getWatchedList(pid);
				} else {
					return _this2.getUnwatchedList(pid);
				}
			})
			// 7a) If the list doesn’t exist, make a new, empty one.
			['catch'](function (error) {
				var otherType = reviewType === "unwatched" ? "watched" : "unwatched";
				var endpoint = "athlete_" + otherType + "_reviews";
				if (_this2.errorHandler.isNoRecordError(error, pid, endpoint)) {
					return _this2._createNewList(otherType, pid);
				} else {
					throw new Error(error);
				}
			})
			// 8) Remove the video from that list.
			.then(function (otherReviewList) {
				return _this2.removeVideo(otherReviewList, video);
			})['catch'](function (error) {
				var message = "A video with id " + video.id + " was not found and could not be removed.";
				if (error.message !== message) {
					_this2.errorHandler.throwIt(error);
				}
			});
		},

		moveToWatched: function moveToWatched(video) {
			return this.moveReview(video, "watched");
		},

		moveToUnwatched: function moveToUnwatched(video) {
			return this.moveReview(video, "unwatched");
		},

		autoMoveToWatched: function autoMoveToWatched(video) {
			if (video.get('played')) {
				this.moveToWatched(video);
			}
		},

		_getList: function _getList(reviewType, pid) {
			if (!reviewType) {
				throw new Error("No reviewType was provided to _getList(). reviewType must be 'watched' or 'unwatched'.");
			}

			if (reviewType !== "watched" && reviewType !== "unwatched") {
				throw new Error("reviewType: " + reviewType + " was provided to _getList(). reviewType must be 'watched' or 'unwatched'.");
			}

			if (!pid) {
				throw new Error("No pid was provided to _getList().");
			}

			return get(this, 'store').findRecord('athlete-' + reviewType + '-review', pid);
		},

		_createNewList: function _createNewList(reviewType, pid) {
			if (!reviewType) {
				throw new Error("No reviewType was provided to _getList(). reviewType must be 'watched' or 'unwatched'.");
			}

			if (reviewType !== "watched" && reviewType !== "unwatched") {
				throw new Error("reviewType: " + reviewType + " was provided to _getList(). reviewType must be 'watched' or 'unwatched'.");
			}

			if (!pid) {
				throw new Error("No pid was provided to _getList().");
			}

			return get(this, 'store').createRecord('athlete-' + reviewType + '-review', {
				id: pid
			});
		},

		getUnwatchedList: function getUnwatchedList(pid) {
			return this._getList("unwatched", pid);
		},

		getWatchedList: function getWatchedList(pid) {
			return this._getList("watched", pid);
		},

		removeVideo: function removeVideo(list, video) {
			if (!list) {
				throw new Error("No list was provided to removeVideo().");
			}

			if (!video) {
				throw new Error("No video was provided to removeVideo().");
			}

			var videoToBeDestroyed = get(list, 'videos').findBy('id', video.id);

			if (!videoToBeDestroyed) {
				throw new Error("A video with id " + video.id + " was not found and could not be removed.");
			}

			return videoToBeDestroyed.destroyRecord().then(function () {
				return list.save();
			});
		},

		addVideo: function addVideo(list, video) {
			if (!list) {
				throw new Error("No list was provided to addVideo().");
			}

			if (!video) {
				throw new Error("No video was provided to addVideo().");
			}

			var videoToBeAdded = get(list, 'videos').findBy('id', video.id);

			// if(videoToBeAdded) {
			// 	throw new Error("A video with id " + video.id + " already exists for list id "+list.id+".");
			// }

			if (!videoToBeAdded) {
				get(list, 'videos').addObject(video);
				return list.save().then(function () {
					if (list.currentState.stateName !== "root.deleted.saved") {
						return list.reload();
					} else {
						return true;
					}
				});
			}
		},

		createNewVideoListItem: function createNewVideoListItem(video, reviewType) {
			var _this3 = this;

			if (!video) {
				throw { name: "No video", message: "You must provide a video to createNewVideoListItem()." };
			}

			if (!reviewType) {
				throw new Error("No reviewType was provided to _getList(). reviewType must be 'watched' or 'unwatched'.");
			}

			if (reviewType !== "watched" && reviewType !== "unwatched") {
				throw new Error("reviewType: " + reviewType + " was provided to _getList(). reviewType must be 'watched' or 'unwatched'.");
			}

			var id = get(video, 'id');

			var newItem = {
				id: id,
				created: get(video, 'created'),
				created_by: get(video, 'created_by'),
				athletes: get(video, 'athletes'),
				tags: get(video, 'tags'),
				coaches: get(video, 'coaches'),
				organization: get(video, 'organization')
			};

			return new RSVP.Promise(function (resolve, reject) {
				try {
					var newRecord = get(_this3, 'store').createRecord(reviewType + '-list-item', newItem);
					resolve(newRecord);
				} catch (error) {
					var message = "Assertion Failed: The id " + id + " has already been used with another record of type merlin@model:" + reviewType + "-list-item:.";
					if (error.message === message) {
						resolve(get(_this3, 'store').findRecord(reviewType + "-list-item", id));
					} else {
						reject(error);
					}
				}
			});
		}
	});
});