define("merlin/components/video-list", ["exports", "ember"], function (exports, _ember) {
	var Component = _ember["default"].Component;
	var inject = _ember["default"].inject;
	var computed = _ember["default"].computed;
	var get = _ember["default"].get;
	var set = _ember["default"].set;
	exports["default"] = Component.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		currentRoute: inject.service(),

		///////////////////////////////////////
		////////  Component Defintion  ////////
		///////////////////////////////////////

		tagName: "",

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		currentActiveVideo: "",
		group: true,

		///////////////////////////////////////
		////////  Computed Properties  ////////
		///////////////////////////////////////

		currentDynamicSegment: computed("currentRoute.currentDynamicSegment", function () {
			return get(this, "currentRoute.currentDynamicSegment");
		}),

		videoGroups: computed('videos', 'group', function () {
			var videos = this.get('videos');

			var videoGroups = [];
			var videoGroupIndex = -1;
			var previousTimestamp = "";

			if (videos) {

				if (get(this, "group")) {
					videos.forEach(function (item) {
						var originalTimestamp = item.get("created");
						var formattedTimestamp = originalTimestamp.split("T")[0];

						if (formattedTimestamp !== previousTimestamp) {
							var newVideoGroup = {
								heading: originalTimestamp,
								videos: []
							};
							videoGroups.addObject(newVideoGroup);
							previousTimestamp = formattedTimestamp;
							videoGroupIndex = videoGroupIndex + 1;
						}

						videoGroups.objectAt(videoGroupIndex).videos.addObject(item);
					});

					videoGroups.forEach(function (videoGroup) {
						videoGroup.videos.reverse();
					});

					return videoGroups.reverse();
				} else {
					return videos;
				}
			} else {
				return [];
			}
		}),

		/////////////////////////////////////
		////////  Lifecycle Methods  ////////
		/////////////////////////////////////

		didReceiveAttrs: function didReceiveAttrs() {
			var currentDynamicSegment = get(this, "currentRoute.currentDynamicSegment");
			var currentActiveVideo = get(this, "currentActiveVideo");

			if (currentActiveVideo !== currentDynamicSegment) {
				set(this, "currentActiveVideo", currentDynamicSegment);
			}
		},

		///////////////////////////
		////////  Actions  ////////
		///////////////////////////

		actions: {
			setCurrentActiveVideo: function setCurrentActiveVideo(vid) {
				set(this, "currentActiveVideo", vid);
			}
		}
	});
});