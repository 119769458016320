define('merlin/components/header-site', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
	exports['default'] = _ember['default'].Component.extend({
		tagName: "header",
		classNames: ['container-header-site'],

		userPanelIsOpen: false,

		isBeta: _ember['default'].computed('', function () {
			return _merlinConfigEnvironment['default'].beta;
		}),

		actions: {
			toggleUserPanel: function toggleUserPanel() {
				if (this.get('userPanelIsOpen')) {
					this.set('userPanelIsOpen', false);
				} else {
					this.set('userPanelIsOpen', true);
				}
			},

			openNewsModal: function openNewsModal() {
				this.sendAction('openNewsModal');
			},

			signOut: function signOut() {
				this.sendAction('signOut');
			}
		}
	});
});