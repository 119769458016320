define("merlin/services/news", ["exports", "ember"], function (exports, _ember) {
	var Service = _ember["default"].Service;
	var RSVP = _ember["default"].RSVP;
	var $ = _ember["default"].$;
	exports["default"] = Service.extend({
		getNews: function getNews() {
			return new RSVP.Promise(function (resolve, reject) {
				$.getJSON("changelog.json").then(function (json) {
					resolve(json);
				}, function (reason) {
					reject(reason);
				});
			});
		}
	});
});