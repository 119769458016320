define('merlin/services/raven', ['exports', 'ember-cli-deploy-sentry/services/raven'], function (exports, _emberCliDeploySentryServicesRaven) {
  exports['default'] = _emberCliDeploySentryServicesRaven['default'].extend({

    unhandledPromiseErrorMessage: '',

    captureException: function captureException() /* error */{
      this._super.apply(this, arguments);
    },

    captureMessage: function captureMessage() /* message */{
      return this._super.apply(this, arguments);
    },

    enableGlobalErrorCatching: function enableGlobalErrorCatching() {
      return this._super.apply(this, arguments);
    },

    ignoreError: function ignoreError(error) {
      return error.name === 'TransitionAborted';
    },

    callRaven: function callRaven() /* methodName, ...optional */{
      return this._super.apply(this, arguments);
    }
  });
});