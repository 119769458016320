define('merlin/controllers/success', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    // isCoach: true,

    isCoach: _ember['default'].computed("model", function () {
      var role = this.get("model").get("role");
      return role === "coach";
    }),

    isBeta: _ember['default'].computed("", function () {
      return _merlinConfigEnvironment['default'].beta;
    }),

    apex: _ember['default'].computed("", function () {
      return _merlinConfigEnvironment['default'].apex;
    }),

    dbat: _ember['default'].computed("", function () {
      return _merlinConfigEnvironment['default'].dbat;
    }),

    appStoreLink: _ember['default'].computed("", function () {
      return _merlinConfigEnvironment['default'].appStoreLink;
    })
  });
});