define('merlin/controllers/confirm', ['exports', 'ember', 'merlin/validations/forgot-password', 'merlin/validations/email'], function (exports, _ember, _merlinValidationsForgotPassword, _merlinValidationsEmail) {
	var Controller = _ember['default'].Controller;
	var computed = _ember['default'].computed;
	var get = _ember['default'].get;
	var set = _ember['default'].set;
	exports['default'] = Controller.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		firebaseWrapper: _ember['default'].inject.service(),

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		queryParams: ['mode', 'oobCode'],

		mode: null,
		oobCode: null,

		EmailValidations: _merlinValidationsEmail['default'],
		ForgotPasswordValidations: _merlinValidationsForgotPassword['default'],

		forgotPasswordFormOptions: {
			fields: [{
				id: "newPassword",
				label: "NEW PASSWORD",
				type: "password",
				autocapitalize: "off",
				spellcheck: false,
				autocorrect: "off"
			}, {
				id: "confirmNewPassword",
				label: "CONFIRM PASSWORD",
				type: "password",
				autocapitalize: "off",
				spellcheck: false,
				autocorrect: "off"
			}],

			buttons: [{
				label: "Change My Password"
			}],

			data: {
				newPassword: "",
				confirmNewPassword: ""
			},

			serverErrors: {}
		},

		resetPasswordFormOptions: {
			fields: [{
				id: "email",
				label: "YOUR EMAIL"
			}],

			buttons: [{
				label: "Request a reset."
			}],

			data: {
				email: ""
			},

			serverErrors: {}
		},

		showActionCodeError: false,
		showRequestModal: false,

		///////////////////////////////////////
		////////  Computed Properties  ////////
		///////////////////////////////////////

		paramsAreValid: computed('mode', 'oobCode', function () {

			var modePresent = !this.tools.isEmpty(get(this, 'mode'));
			var modeValid = get(this, 'mode') === "resetPassword";
			var oobPresent = !this.tools.isEmpty(get(this, 'oobCode'));

			return modePresent && modeValid && oobPresent;
		}),

		///////////////////////////
		////////  Actions  ////////
		///////////////////////////

		actions: {
			clickedPasswordReset: function clickedPasswordReset() {
				set(this, "showRequestModal", true);
			},

			closeDialogue: function closeDialogue() {
				set(this, "showRequestModal", false);
			},

			goToLogin: function goToLogin() {
				this.transitionToRoute("login");
			},

			submitedChangePassword: function submitedChangePassword() {
				var _this = this;

				var data = get(this, "forgotPasswordFormOptions.data");
				var newPassword = get(data, 'newPassword');
				var code = get(this, 'oobCode');

				this.get('firebaseWrapper').confirmPasswordReset(code, newPassword).then(function () {
					var message = "<p>Your password has been changed.</p>";
					_this.EventBus.publish("sendNotification", message);

					_this.transitionToRoute("login");
				})['catch'](function (error) {
					if (error.code === "auth/expired-action-code" || error.code === "auth/invalid-action-code") {
						_this.set('showActionCodeError', true);
					} else {
						_this.errorHandler.throwIt(error);
					}
				});
			}
		}
	});
});