define("merlin/serializers/default-list", ["exports", "emberfire/serializers/firebase"], function (exports, _emberfireSerializersFirebase) {
  exports["default"] = _emberfireSerializersFirebase["default"].extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      // console.log(payload);

      payload.videos = {};

      for (var attribute in payload) {
        if (payload.hasOwnProperty(attribute)) {
          // console.log(attribute, payload[attribute]);
          if (attribute !== "id" && attribute !== "videos") {
            payload.videos[attribute] = true;
            delete payload[attribute];
          }
        }
      }
      return this._super.apply(this, arguments);
    }
  });
});