define("merlin/services/current-route", ["exports", "ember"], function (exports, _ember) {
  var Service = _ember["default"].Service;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var getWithDefault = _ember["default"].getWithDefault;
  var set = _ember["default"].set;
  exports["default"] = Service.extend({

    currentDynamicSegment: "",

    setCurrentDynamicSegment: function setCurrentDynamicSegment(segment) {
      set(this, "currentDynamicSegment", segment);
    },

    // Get properties from the injected applicationController
    // (injected via custom applicationController initializer)
    currentRouteName: computed.readOnly('applicationController.currentRouteName'),

    routeHierarchy: computed("currentRouteName", {
      get: function get() {
        var currentRouteName = getWithDefault(this, "currentRouteName", false);

        if (!currentRouteName) {
          throw new Error("[current-route] Could not find a curent route");
        }

        var routeNames = this._splitCurrentRouteName(currentRouteName);
        var filteredRouteNames = this._filterIndexRoutes(routeNames);

        return filteredRouteNames;
      },

      set: function set() {
        console.warn('[current-route] `routeHierarchy` is read only');
      }
    }),

    currentNode: computed("routeHierarchy", function () {
      return get(this, "routeHierarchy.lastObject");
    }),

    _splitCurrentRouteName: function _splitCurrentRouteName(currentRouteName) {
      return currentRouteName.split('.');
    },

    _filterIndexRoutes: function _filterIndexRoutes(routeNames) {
      return routeNames.filter(function (name) {
        return name !== 'index';
      });
    }
  });
});