define('merlin/routes/error-test', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		model: function model() {
			// throw new Error('This is a test error');
			this.errorHandler.throwIt({ message: 'This is a errorHandler test error' });
		},

		actions: {
			error: function error(_error) {
				return this.errorHandler.log(_error);
			}
		}
	});
});