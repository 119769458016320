define("merlin/components/privacy-content", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  exports["default"] = Component.extend({

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    tagName: ""
  });
});