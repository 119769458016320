define("merlin/components/link-lightbox", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		// tagName: "a",
		classNames: ["container-link-video"],
		// attributeBindings: ["dataType:data-type", "dataAutoplay:data-autoplay", 'video:href'],

		// dataType: "vimeo",
		// dataAutoplay: true,

		didInsertElement: function didInsertElement() {
			// const elementId = "#" + this.get('elementId');
			var elementId = "#" + this.get('linkId');

			_ember["default"].$(document).ready(function () {
				/* default settings */
				_ember["default"].$(elementId).venobox();

				/* custom settings */
				// $('.venobox_custom').venobox({
				//     framewidth: '400px',        // default: ''
				//     frameheight: '300px',       // default: ''
				//     border: '10px',             // default: '0'
				//     bgcolor: '#5dff5e',         // default: '#fff'
				//     titleattr: 'data-title',    // default: 'title'
				//     numeratio: true,            // default: false
				//     infinigall: true            // default: false
				// });

				/* auto-open #firstlink on page load */
				// $("#firstlink").venobox().trigger('click');
			});
		},

		linkId: _ember["default"].computed('elementId', function () {
			return "link" + this.get('elementId');
		}),

		click: function click() {
			var linkID = "#" + this.get('linkId');
			_ember["default"].$(linkID).venobox().trigger('click');
			this.sendAction('action');
		},

		mouseEnter: function mouseEnter() {
			var linkID = "#" + this.get('linkId');
			_ember["default"].$(linkID).addClass('is-hovered');
		},

		mouseLeave: function mouseLeave() {
			var linkID = "#" + this.get('linkId');
			_ember["default"].$(linkID).removeClass('is-hovered');
		},

		mouseDown: function mouseDown() {
			var linkID = "#" + this.get('linkId');
			_ember["default"].$(linkID).addClass('is-active');
		},

		mouseUp: function mouseUp() {
			var linkID = "#" + this.get('linkId');
			_ember["default"].$(linkID).removeClass('is-active');
		}
	});
});