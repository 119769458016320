define('merlin/components/account-management', ['exports', 'ember', 'merlin/validations/change-password'], function (exports, _ember, _merlinValidationsChangePassword) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({

    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    authenticator: inject.service(),
    session: inject.service(),
    sessionManager: inject.service(),
    firebaseWrapper: inject.service(),

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    tagName: "",

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    showChangePasswordDialogue: false,

    changePasswordNavigationOptions: {
      type: "modal",
      title: "Change Password",
      left: {
        label: "Cancel",
        actionName: "cancelChangePassword"
      }
    },

    changePasswordFormOptions: {
      fields: [{
        id: "oldPassword",
        label: "OLD PASSWORD*",
        type: "password",
        autocapitalize: "off",
        spellcheck: false,
        autocorrect: "off"
      }, {
        id: "newPassword",
        label: "NEW PASSWORD*",
        type: "password",
        autocapitalize: "off",
        spellcheck: false,
        autocorrect: "off"
      }, {
        id: "confirmNewPassword",
        label: "CONFIRM NEW PASSWORD*",
        type: "password",
        autocapitalize: "off",
        spellcheck: false,
        autocorrect: "off"
      }],

      buttons: [{
        label: "Change My Password"
      }],

      data: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      },

      serverErrors: {}
    },

    ChangePasswordValidations: _merlinValidationsChangePassword['default'],

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      this.set('router', _ember['default'].getOwner(this).lookup('router:main'));
    },

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      signOut: function signOut() {
        var _this = this;

        get(this, "sessionManager").unload();

        /*true*/get(this, 'authenticator').safelyCloseSession().then(function () {
          get(_this, "router").transitionTo("login");
        })['catch'](function (error) {
          _this.errorHandler.throwIt(error);
        });
      },

      cancelChangePassword: function cancelChangePassword() {
        set(this, "showChangePasswordDialogue", false);
      },

      clickedChangePassword: function clickedChangePassword() {
        set(this, "showChangePasswordDialogue", true);
      },

      submitedChangePassword: function submitedChangePassword() {
        var _this2 = this;

        // console.log(...args);
        var options = get(this, "changePasswordFormOptions");
        var data = get(options, "data");

        var oldPassword = get(data, "oldPassword");
        var newPassword = get(data, "newPassword");

        get(this, 'firebaseWrapper').changePassword(oldPassword, newPassword).then(function () {
          _this2.EventBus.publish("sendNotification", "Your password was reset.");
          set(_this2, "showChangePasswordDialogue", false);
        })['catch'](function (error) {
          if (error.code === "auth/user-mismatch" || error.code === "auth/user-not-found" || error.code === "auth/invalid-credential" || error.code === "auth/wrong-password") {
            set(options, "serverErrors", { key: "oldPassword", validation: "Incorrect Password" });
          } else if (error.code === "auth/weak-password") {
            set(options, "serverErrors", { key: "newPassword", validation: "Weak Password" });
          } else {
            _this2.errorHandler.throwIt(error);
          }
        });
      }
    }
  });
});