define('merlin/components/link-video', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({

		tagName: 'li',
		classNames: ['container-item-list-videos'],
		classNameBindings: ['isActive'],

		isActive: _ember['default'].computed('currentVideoID', function () {
			return this.get('videoID') === this.get('currentVideoID');
		}),

		click: function click() {
			this.attrs.clicked();
		}
	});
});