define('merlin/components/avatar-user', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({

		classNames: ['container-avatar-user'],
		classNameBindings: ['isInteractive', 'isActive', 'isSmall'],

		isActive: false,
		isInteractive: false,
		isSmall: false,
		avatarURL: null,

		hasAvatar: _ember['default'].computed('avatarURL', function () {

			var avatar = this.get('avatarURL');

			if (avatar) {
				return true;
			} else {
				return false;
			}
		}),

		hasName: _ember['default'].computed('user.fname', 'user.lname', function () {
			if (this.get('user.fname') || this.get('user.lname')) {
				return true;
			} else {
				return false;
			}
		}),

		firstLetter: _ember['default'].computed('user.fname', function () {
			return this.get('user.fname').charAt(0);
		}),

		lastLetter: _ember['default'].computed('user.lname', function () {
			return this.get('user.lname').charAt(0);
		})
	});
});