define('merlin/torii-adapters/application', ['exports', 'ember', 'emberfire/torii-adapters/firebase', 'merlin/config/environment'], function (exports, _ember, _emberfireToriiAdaptersFirebase, _merlinConfigEnvironment) {
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  exports['default'] = _emberfireToriiAdaptersFirebase['default'].extend({
    store: inject.service(),
    errorHandler: inject.service("error-handler"),

    open: function open(options) {
      var _this = this;

      var finalData = undefined;
      // Perform standard Firebase authentication
      return this._super(options).then(function (data) {
        // Retrieve the user's organization and add to the session
        finalData = data;
        return get(_this, 'store').findRecord('user', data.uid);
      }).then(function (user) {
        return get(user, 'authorizations');
      }).then(function (authorizations) {
        if (get(authorizations, 'length') !== 0) {
          finalData.organization = authorizations.objectAt(0).id;
          finalData.pid = authorizations.objectAt(0).get('personID');
        }
        return finalData;
      })['catch'](function (error) {
        var message = "no record was found at " + _merlinConfigEnvironment['default'].firebase.databaseURL + "/users/" + finalData.uid;

        if (error.message === message) {
          return finalData;
        } else {
          get(_this, 'errorHandler').throwIt(error);
        }
      });
    }
  });
});