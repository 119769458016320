define('merlin/components/x-radio', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var guidFor = _ember['default'].guidFor;

  var id = 0;

  exports['default'] = Component.extend({

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    classNames: ["container-input radio"],
    classNameBindings: ['isError', 'hide:is-hidden'],

    ///////////////////////////////////////
    ////////  Computed Properties  ////////
    ///////////////////////////////////////

    inputId: computed(function () {
      var guid = guidFor(this);
      id++;
      return 'input-' + guid + '-' + id;
    }),

    isError: computed("errors", function () {
      return get(this, "errors") !== undefined;
    }),

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      changed: function changed(value) {
        set(this, "selectedOption", value);
        get(this, "onChange")(value);
      }
    }
  });
});