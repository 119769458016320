define('merlin/initializers/application-controller', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('service:current-route', 'applicationController', 'controller:application');
  }

  exports['default'] = {
    name: 'application-controller',
    initialize: initialize
  };
});