define('merlin/components/control-segmented', ['exports', 'ember'], function (exports, _ember) {
	var Component = _ember['default'].Component;
	var get = _ember['default'].get;
	var computed = _ember['default'].computed;
	exports['default'] = Component.extend({

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		segments: [],

		///////////////////////////////////////
		////////  Component Defintion  ////////
		///////////////////////////////////////

		tagName: 'ul',
		classNames: ['container-control-segmented'],
		classNameBindings: ['countClass'],

		///////////////////////////////////////
		////////  Computed Properties  ////////
		///////////////////////////////////////

		countClass: computed('count', function () {
			var count = get(this, 'count');
			return 'count-' + count;
		}),

		///////////////////////////
		////////  Actions  ////////
		///////////////////////////

		actions: {
			setActiveSegment: function setActiveSegment(key) {

				var segments = get(this, 'segments');

				if (get(segments, 'length') > 0) {
					segments.forEach(function (item) {
						item.active = false;
					});
				}

				var segment = segments.findBy('key', key);

				if (segment) {
					segment.active = true;
				} else {
					segments.addObject({ key: key, active: true });
				}
			}
		}
	});
});