define("merlin/controllers/settings", ["exports", "ember"], function (exports, _ember) {
  var Controller = _ember["default"].Controller;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Controller.extend({

    navigationOptions: {
      type: "index",
      title: "Settings",
      left: {
        label: "Done",
        actionName: "goTo",
        actionParameter: "videos"
      }
    },

    // Used to clear state in list component.
    tranitioningAway: false,

    actions: {
      parseAction: function parseAction(actionName) {
        get(this, actionName)(this, arguments);
      }
    },

    goTo: function goTo(context, params) {
      var target = params[1];

      if (target) {
        set(context, "tranitioningAway", true);
        context.transitionToRoute(target);
      } else {
        throw new Error('No target provided to goTo()');
      }
    }
  });
});