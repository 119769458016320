define('merlin/components/event-tracker', ['exports', 'ember', 'merlin/config/environment'], function (exports, _ember, _merlinConfigEnvironment) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({

    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    metrics: inject.service(),

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    tagName: "",

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    actionValue: 1,
    debug: false,

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      trackEvent: function trackEvent() {

        var environments = _merlinConfigEnvironment['default'].metricsAdapters[0].environments;
        var allowed = environments.find(function (item) {
          return item === _merlinConfigEnvironment['default'].environment;
        });

        if (allowed || get(this, "debug")) {
          get(this, 'metrics').trackEvent('GoogleAnalytics', {
            // (required) The name you supply for the group of objects you want to track.
            category: get(this, "actionCateogory"),
            // (required) A string that is uniquely paired with each category, and commonly used to define the type of user interaction for the web object.
            action: get(this, "actionType"),
            // (optional) string to provide additional dimensions to the event data.
            label: get(this, "actionLabel"),
            // (optional) An integer that you can use to provide numerical data about the user event.
            value: get(this, "actionValue"),
            // (optional) boolean that when set to true, indicates that the event hit will not be used in bounce-rate calculation.
            noninteraction: false
          });
        }
      }
    }
  });
});