define('merlin/initializers/tools', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('component', 'tools', 'service:tools');
    application.inject('controller', 'tools', 'service:tools');
    application.inject('route', 'tools', 'service:tools');

    // Inject into specific services
    application.inject('service:sessionManager', 'tools', 'service:tools');
  }

  exports['default'] = {
    name: 'tools',
    initialize: initialize
  };
});