define("merlin/components/table-cell-video", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var inject = _ember["default"].inject;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({
    tools: inject.service(),

    tagName: "li",
    classNames: ["item-video-videoList"],
    classNameBindings: ["isActive", "isHovered", "classes"],

    isActive: computed("currentActiveCell", "video", function () {
      return get(this, "currentActiveCell") === get(this, "video.id");
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('router', _ember["default"].getOwner(this).lookup('router:main'));
    },

    isHovered: false,

    mouseEnter: function mouseEnter() {
      set(this, "isHovered", true);
    },

    mouseLeave: function mouseLeave() {
      set(this, "isHovered", false);
    },

    click: function click() {
      var model = get(this, "video");
      var listID = get(this, "listID");
      var target = "videos.list.video";
      if (listID === "inbox" || listID === "todo" || listID === "favorites" || listID === "captures" || listID === "reviews" || listID === "shared" || listID === "search") {
        target = "videos." + listID + ".video";
      }
      get(this, "router").transitionTo(target, get(model, "id"));

      var onClick = get(this, "onClick");
      if (get(this, "tools").isFunction(onClick)) {
        onClick();
      }
    }
  });
});