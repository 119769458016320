define('merlin/controllers/login', ['exports', 'ember', 'merlin/validations/email', 'merlin/validations/password', 'ember-ajax/errors', 'merlin/emails/subscription-error'], function (exports, _ember, _merlinValidationsEmail, _merlinValidationsPassword, _emberAjaxErrors, _merlinEmailsSubscriptionError) {
	var Controller = _ember['default'].Controller;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	var set = _ember['default'].set;
	var $ = _ember['default'].$;
	exports['default'] = Controller.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		authenticator: inject.service(),
		authorizer: inject.service(),
		sessionManager: inject.service(),
		tools: inject.service(),
		firebaseWrapper: inject.service(),

		/////////////////////////////////////
		////////  Lifecycle Methods  ////////
		/////////////////////////////////////

		init: function init() {
			this._super.apply(this, arguments);
			this.currentLoginFormOptions = get(this, "loginFormEmailOptions");
		},

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		isSubscriptionError: false,
		subscriptionErrorHeading: "Subscription Error",

		subscriptionTo: _merlinEmailsSubscriptionError['default'].to,
		subscriptionSubject: _merlinEmailsSubscriptionError['default'].subject,
		subscriptionBody: _merlinEmailsSubscriptionError['default'].body,
		subscriptionInfo: "",

		queryParams: ['reset'],
		reset: false,

		loginFormEmailOptions: {
			fields: [{
				id: "email",
				label: "EMAIL",
				autocapitalize: "off",
				spellcheck: false,
				autocorrect: "off"
			}, {
				id: "password",
				label: "PASSWORD",
				type: "password",
				hide: true,
				autocapitalize: "off",
				spellcheck: false,
				autocorrect: "off"
			}],
			buttons: [{
				id: "next",
				label: "Next",
				type: "submit"
			}],
			data: {
				email: "",
				password: ""
			},
			validator: _merlinValidationsEmail['default'],
			formAction: "tryEmail"
		},

		loginFormPasswordOptions: {
			fields: [{
				id: "password",
				label: "PASSWORD",
				type: "password",
				focus: true,
				autocapitalize: "off",
				spellcheck: false,
				autocorrect: "off"
			}],
			buttons: [{
				id: "login",
				label: "Log in",
				type: "submit"
			}, {
				id: "back",
				actionName: "transitionToForm",
				actionParameter: "email",
				classes: "button-borderless blue",
				label: "Back",
				type: "button",
				validate: false
			}],
			data: {
				password: ""
			},
			validator: _merlinValidationsPassword['default'],
			formAction: "authenticateWithPassword"
		},

		passwordResetOptions: {
			label: "REQUEST PASSWORD RESET",
			actionName: "clickedPasswordReset"
		},

		showRequestModal: false,

		currentAdditionalActionOptions: null,

		///////////////////////////
		////////  Methods  ////////
		///////////////////////////

		authenticateWithPassword: function authenticateWithPassword() {
			var _this = this;

			var authenticator = this.get('authenticator');
			var sessionManager = this.get('sessionManager');

			var email = get(this, 'loginFormEmailOptions.data.email');
			var password = get(this, 'loginFormPasswordOptions.data.password');

			var options = get(this, "loginFormPasswordOptions");
			var buttons = get(options, "buttons");
			var button = buttons.objectAt(0);
			set(button, "loading", true);

			// Attempt to authenticate.
			authenticator.safelyAuthenticate(email, password).then(function (data) {
				options.buttons[0].loading = false;
				// Find out where to send the user.
				return get(_this, "authorizer").shouldIBeHere(data.uid);
			}).then(function () {
				// Send the user to /videos
				return sessionManager.smartTransition(_this, '/videos');
			}).then(function () {
				_this.resetForm();
			})['catch'](function (error) {
				options.buttons[0].loading = false;
				if (error.code === "auth/wrong-password") {
					set(_this, "loginFormPasswordOptions.serverErrors", { key: "password", validation: "Incorrect Password" });
				} else if (error.name === "SignupNotCompleted") {
					// If signup is not completed redirect to /signup.
					get(_this, "sessionManager").unload();
					return authenticator.safelyCloseSession().then(function () {
						_this.transitionToRoute('/signup');
					})['catch'](function (error) {
						_this.errorHandler.throwIt(error);
					});
				} else if (error.name === "TosNotAccepted") {
					// If the TOS is not accepted redirect to /terms.
					_this.transitionToRoute('/terms');
				} else if (error.name === "UserIsCoach") {
					// Coaches can’t use the app, so for now redirect them to their special page.
					sessionManager.smartTransition(_this, '/coach');
				} else {
					_this.errorHandler.throwIt(error);
				}
			});
		},

		clickedPasswordReset: function clickedPasswordReset() {
			set(this, "showRequestModal", true);
		},

		resetForm: function resetForm() {
			set(this, "loginFormEmailOptions.data.email", "");
			set(this, "loginFormEmailOptions.data.password", "");
			set(this, "loginFormPasswordOptions.data.password", "");
			set(this, "currentLoginFormOptions", get(this, "loginFormEmailOptions"));
			set(this, "currentAdditionalActionOptions", null);
		},

		transitionToForm: function transitionToForm(target) {
			if (!get(this, "tools").isEmpty(target)) {

				var loginFormPasswordOptions = get(this, "loginFormPasswordOptions");
				var loginFormEmailOptions = get(this, "loginFormEmailOptions");

				loginFormEmailOptions.buttons[0].loading = false;
				loginFormPasswordOptions.buttons[0].loading = false;

				if (target === "password") {
					var passwordManagerPassword = get(loginFormEmailOptions, "data.password");

					if (!get(this, "tools").isEmpty(passwordManagerPassword)) {
						set(this, "loginFormPasswordOptions.data.password", passwordManagerPassword);
					}

					set(this, "currentLoginFormOptions", loginFormPasswordOptions);
					set(this, "currentAdditionalActionOptions", get(this, "passwordResetOptions"));
				} else if (target === "email") {
					set(this, "currentLoginFormOptions", loginFormEmailOptions);
					set(this, "currentAdditionalActionOptions", null);
				} else if (target === "sso") {
					set(this, "currentLoginFormOptions", {
						isSSO: true,
						data: arguments[1]
					});
				} else {
					throw new Error('The target "' + target + '" does not exist.');
				}
			} else {
				throw new Error("No target provided to transitionToForm()");
			}
		},

		tryEmail: function tryEmail() {
			var _this2 = this;

			var options = get(this, "loginFormEmailOptions");
			var email = get(options, "data.email");
			options.buttons[0].loading = true;

			get(this, "authenticator").getAccountType(email).then(function (response) {
				var organizations = $.map(response.data.organizations, function (value) {
					return [value];
				});

				var method = get(_this2, "tools").isEmpty(organizations[0]) ? false : organizations[0]['login-method'];

				if (method === "email") {
					_this2.transitionToForm("password");
				} else if (method === "sso") {
					// Proceed to Partner Site
					_this2.transitionToForm("sso", organizations[0]);
				} else {
					throw new Error('The login method ' + method + ' is not supported.');
				}
			})['catch'](function (error) {
				if ((0, _emberAjaxErrors.isNotFoundError)(error)) {
					set(_this2, "loginFormEmailOptions.serverErrors", { key: "email", validation: "Account Not Found" });
				} else if ((0, _emberAjaxErrors.isForbiddenError)(error)) {
					options.buttons[0].loading = false;
					var message = error.errors[0].detail.message;
					if (_this2.tools.isNotEmpty(message)) {
						set(_this2, "subscriptionErrorHeading", message);
						var _email = get(_this2, "loginFormEmailOptions.data.email");
						set(_this2, "subscriptionInfo", '\n\t\t\t\t\tError: ' + message + '\n\t\t\t\t\tEmail: ' + _email + '\n\t\t\t\t\t');
					}
					set(_this2, "isSubscriptionError", true);
				} else {
					_this2.errorHandler.throwIt(error);
				}
			});
		},

		///////////////////////////
		////////  Actions  ////////
		///////////////////////////

		actions: {

			closeDialogue: function closeDialogue() {
				set(this, "showRequestModal", false);
			},

			parseAction: function parseAction(actionName) {
				var tools = get(this, "tools");
				if (actionName !== false) {
					var action = this[actionName];
					if (tools.isFunction(action)) {
						for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
							args[_key - 1] = arguments[_key];
						}

						this[actionName].apply(this, args);
					} else {
						throw new Error('The function ' + actionName + ' does not exist in login controller’s scope.');
					}
				}
			},

			cancelLoginAttempt: function cancelLoginAttempt() {
				set(this, "isSubscriptionError", false);
			}
		}
	});
});