define('merlin/routes/sso', ['exports', 'ember', 'merlin/errors/SSOError'], function (exports, _ember, _merlinErrorsSSOError) {
	exports['default'] = _ember['default'].Route.extend({

		authenticator: _ember['default'].inject.service(),

		beforeModel: function beforeModel(transition) {
			var _this = this;

			if (this.get('session').get('isAuthenticated')) {
				transition.abort();
				this.transitionTo('/videos');
			} else {
				var _ret = (function () {
					var _self = _this;
					var authenticator = _this.get('authenticator');
					if (transition.queryParams.token) {
						// authenticate with token
						var token = transition.queryParams.token;
						return {
							v: authenticator.authenticateWithToken(token).then(function () {
								// If everything checks out, move on to the app.
								return _self.transitionTo('/videos');
							})['catch'](function (error) {
								// Likely a Firebase error
								var authenticationError = {
									isCustom: true,
									title: "Single Sign-On Failed",
									message: error.message,
									returnToSource: true,
									source: 'login',
									actionText: "Login with an APEX Account",
									keepParams: false
								};
								_self.errorHandler.throwIt(authenticationError);
							})
						};
					} else if (transition.queryParams.error) {
						// The organization probably did something wrong
						var message = atob(transition.queryParams.error);
						_self.errorHandler.throwIt(new _merlinErrorsSSOError['default'](message));
					} else {
						// Otherwise a token is required
						_self.errorHandler.throwIt(new _merlinErrorsSSOError['default']("Missing token"));
					}
					return {
						v: true
					};
				})();

				if (typeof _ret === 'object') return _ret.v;
			}
		},

		actions: {
			error: function error(_error) {
				return this.errorHandler.log(_error);
			}
		}
	});
});