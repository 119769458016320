define('merlin/routes/videos', ['exports', 'ember'], function (exports, _ember) {
	var Route = _ember['default'].Route;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	var RSVP = _ember['default'].RSVP;
	exports['default'] = Route.extend({

		/************
   * Services *
   ************/

		authenticator: inject.service(),
		sessionManager: inject.service(),
		authorizer: inject.service(),

		/*********************
   * Lifecycle Methods *
   *********************/

		beforeModel: function beforeModel() {
			var _this = this;

			this._super.apply(this, arguments);
			var authenticator = this.get('authenticator');
			var uid = this.get('session.content.uid');

			if (!this.tools.isEmpty(uid)) {
				get(this, "authorizer").shouldIBeHere(uid)['catch'](function (error) {
					if (error.name === "NotAuthenticated") {
						// If we’re not logged in, let the login page load.
						_this.transitionTo('login');
					} else if (error.name === "SignupNotCompleted") {
						// If signup is not completed redirect to /signup.
						get(_this, "sessionManager").unload();
						return authenticator.safelyCloseSession().then(function () {
							_this.transitionTo('/signup');
						})['catch'](function (error) {
							_this.errorHandler.throwIt(error);
						});
					} else if (error.name === "TosNotAccepted") {
						// If the TOS is not accepted redirect to /terms.
						_this.transitionTo('/terms');
					} else if (error.name === "UserIsCoach") {
						// Coaches can’t use the app, so for now redirect them to their special page.
						_this.transitionTo('/coach');
					} else {
						_this.errorHandler.throwIt(error);
					}
				});
			}
		},

		model: function model() {
			var _this2 = this;

			var pid = this.get('session.content.pid');

			return _ember['default'].RSVP.hash({
				inbox: this.handleEmptyRecord('person_inbox', pid, false)['catch'](function (error) {
					if (error.code === 404) {
						return _this2.createEmptyList('person_inbox', pid);
					} else {
						return _this2.logout(error);
					}
				}),

				todos: this.handleEmptyRecord('person_todo', pid, false)['catch'](function (error) {
					if (error.code === 404) {
						return _this2.createEmptyList('person_todo', pid);
					} else {
						return _this2.logout(error);
					}
				}),

				favorites: this.handleEmptyRecord('person_favorite', pid)['catch'](function (error) {
					if (error.code === 404) {
						return _this2.createEmptyList('person_favorite', pid);
					} else {
						return _this2.logout(error);
					}
				}),

				lists: this.handleEmptyRecord('person_list', pid).then(function (person_list) {
					// try {
					// 	console.log(get(person_list,"person_list_lists"));
					// } catch(error) {
					// 	console.log(error);
					// }
					return get(person_list, "person_list_lists");
				})['catch'](function (error) {
					if (error.code === 404) {
						return [];
					} else {
						return _this2.logout(error);
					}
				})
			});
		},

		/***********
   * Methods *
   ***********/

		logout: function logout(error) {
			var _this3 = this;

			get(this, "sessionManager").unload();
			return get(this, "authenticator").safelyCloseSession().then(function () {
				return _this3.transitionTo('login');
			}).then(function () {
				_this3.errorHandler.throwIt(error);
			})['catch'](function (reason) {
				_this3.errorHandler.throwIt(reason);
			});
		},

		createEmptyList: function createEmptyList(modelName, pid) {
			var newList = this.store.createRecord('' + modelName, {
				id: pid,
				videos: []
			});
			return newList;
		},

		/**
   * Finds a record. If the record is not found, gives the function caller that information so it can fail gracefully.
   *
   * @method handleEmptyRecord
   * @param {string} modelName Name of the record type we are searching for.
   * @param {string} recordID ID of the record we are searching for.
   */

		handleEmptyRecord: function handleEmptyRecord(modelName, recordID) {
			var _this4 = this;

			var pluralize = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

			var organization = get(this, "session.content.organization");

			return new RSVP.Promise(function (resolve, reject) {
				_this4.store.findRecord(modelName, recordID).then(function (record) {
					resolve(record);
				})['catch'](function (error) {
					var endpointModel = pluralize ? modelName + 's' : modelName;
					var endpoint = 'organizations/' + organization + '/' + endpointModel;
					if (_this4.errorHandler.isNoRecordError(error, recordID, endpoint)) {
						var newError = new Error('Record ' + modelName + ' not found.');
						newError.code = 404;
						reject(newError);
					} else {
						reject(error);
					}
				});
			});
		}
	});
});