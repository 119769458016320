define('merlin/components/control-search', ['exports', 'ember', 'npm:fuse.js'], function (exports, _ember, _npmFuseJs) {
	var Component = _ember['default'].Component;
	var computed = _ember['default'].computed;
	var get = _ember['default'].get;
	var set = _ember['default'].set;
	var typeOf = _ember['default'].typeOf;
	exports['default'] = Component.extend({

		///////////////////////////////////////
		////////  Component Defintion  ////////
		///////////////////////////////////////

		classNames: ['container-control-search'],
		classNameBindings: ['isFilled'],

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		debug: false,
		hasCancel: true,

		///////////////////////////////////////
		////////  Computed Properties  ////////
		///////////////////////////////////////

		inputId: computed('elementId', function () {
			return "search-" + get(this, 'elementId');
		}),

		isFilled: computed('query', function () {
			return get(this, 'query.length') > 0;
		}),

		options: computed('keys', function () {
			var keys = get(this, 'keys');
			var debug = get(this, 'debug');

			return {
				keys: keys,
				verbose: debug
			};
		}),

		manager: computed('list', 'options', function () {
			return new _npmFuseJs['default'](get(this, 'list'), get(this, 'options'));
		}),

		queryChanged: _ember['default'].observer('query', function () {
			var query = get(this, 'query');
			var list = get(this, 'list');
			if (query && list) {
				var results = get(this, 'manager').search(query);
				// console.log(get(this,'manager'));
				// console.log(results);
				this._setResults(results);
			} else {
				this._setResults(null);
			}
		}),

		//@poteto
		// https://github.com/poteto/ember-hypersearch/blob/develop/addon/components/hyper-search.js
		_handleAction: function _handleAction(actionName) {
			for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
				args[_key - 1] = arguments[_key];
			}

			if (this.attrs && typeOf(this.attrs[actionName]) === 'function') {
				var _attrs;

				(_attrs = this.attrs)[actionName].apply(_attrs, args);
			} else {
				this.sendAction.apply(this, [actionName].concat(args));
			}
		},

		//@poteto
		// https://github.com/poteto/ember-hypersearch/blob/develop/addon/components/hyper-search.js
		_setResults: function _setResults(results) {
			this._handleAction('handleResults', results);
		},

		///////////////////////////
		////////  Actions  ////////
		///////////////////////////

		actions: {
			clear: function clear() {
				set(this, "query", "");
			},

			cancel: function cancel() {
				get(this, "onCancel")();
			}
		}
	});
});