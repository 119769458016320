define('merlin/services/authorizer', ['exports', 'ember'], function (exports, _ember) {
	var Service = _ember['default'].Service;
	var inject = _ember['default'].inject;
	var RSVP = _ember['default'].RSVP;
	var get = _ember['default'].get;
	exports['default'] = Service.extend({

		/************
   * Services *
   ************/

		store: inject.service(),

		/***********
   * Methods *
   ***********/

		getSignupState: function getSignupState(uid) {
			var _this = this;

			return new RSVP.Promise(function (resolve, reject) {
				get(_this, 'store').findRecord('user', uid).then(function (user) {
					var authorizations = get(user, 'authorizations');
					if (get(authorizations, 'length') > 0) {
						resolve(true);
					} else {
						resolve(false);
					}
				})['catch'](function (error) {
					reject(error);
				});
			});
		},

		getTosState: function getTosState(uid) {
			var _this2 = this;

			return new RSVP.Promise(function (resolve, reject) {
				get(_this2, 'store').findRecord('user', uid).then(function (user) {
					resolve(get(user, 'tos_accepted'));
				})['catch'](function (error) {
					reject(error);
				});
			});
		},

		getRole: function getRole(uid) {
			var _this3 = this;

			return new RSVP.Promise(function (resolve, reject) {
				get(_this3, 'store').findRecord('user', uid).then(function (user) {
					resolve(get(user, 'role'));
				})['catch'](function (error) {
					reject(error);
				});
			});
		},

		shouldIBeHere: function shouldIBeHere(uid) {
			var _this4 = this;

			return new RSVP.Promise(function (resolve, reject) {
				_this4.getSignupState(uid).then(function (hasAuthorizations) {
					if (hasAuthorizations) {
						return _this4.getTosState(uid);
					} else {
						reject({ name: "SignupNotCompleted", message: "You have not completed signup." });
					}
				}).then(function (tosState) {
					if (tosState) {
						return _this4.getRole(uid);
					} else {
						reject({ name: "TosNotAccepted", message: "You have not accepted the TOS." });
					}
				}).then(function (role) {
					if (role === "coach") {
						reject({ name: "UserIsCoach", message: "Coaches don’t have access to the webapp." });
					} else {
						resolve();
					}
				})['catch'](function (error) {
					reject(error);
				});
			});
		}
	});
});