define("merlin/controllers/videos/favorites", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Controller.extend({
		navigationOptions: {
			type: "list",
			title: "Favorites",
			left: {
				icon: "back",
				label: "Videos",
				actionName: "goTo",
				actionParameter: "videos"
			}
		},

		toolbarOptions: {
			buttons: [{
				icon: "search",
				actionName: "openSearch",
				actionParameter: "favorites"
			}]
		}
	});
});