define('merlin/routes/videos/captures', ['exports', 'ember'], function (exports, _ember) {
	var Route = _ember['default'].Route;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	exports['default'] = Route.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		listManager: inject.service(),

		///////////////////////////
		////////  Methods  ////////
		///////////////////////////

		model: function model() {
			return get(this, "listManager").fetchVideos('person_capture', 'person_captures');
		}
	});
});