define('merlin/models/person', ['exports', 'ember', 'ember-data/model', 'ember-data/attr'], function (exports, _ember, _emberDataModel, _emberDataAttr) {
  // import { belongsTo, hasMany } from 'ember-data/relationships';

  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _emberDataModel['default'].extend({
    fname: (0, _emberDataAttr['default'])('string'),
    lname: (0, _emberDataAttr['default'])('string'),
    email: (0, _emberDataAttr['default'])('string'),
    role: (0, _emberDataAttr['default'])('string'),

    nameFull: computed("fname", "lname", function () {
      var fname = get(this, "fname");
      var lname = get(this, "lname");
      return fname + ' ' + lname;
    }),

    nameAbbreviated: computed("fname", "lname", function () {
      var fname = get(this, "fname");
      var lname = get(this, "lname");
      return fname + ' ' + lname.substr(0, 1);
    }),

    nameAbbreviatedWithPeriod: computed("nameAbbreviated", function () {
      var nameAbbreviated = get(this, "nameAbbreviated");
      return nameAbbreviated + '.';
    })
  });
});