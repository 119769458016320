define('merlin/controllers/create-account', ['exports', 'ember', 'merlin/config/environment', 'merlin/validations/createAccount'], function (exports, _ember, _merlinConfigEnvironment, _merlinValidationsCreateAccount) {
  var Controller = _ember['default'].Controller;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Controller.extend({

    orgName: null,
    firstName: null,
    lastName: null,
    email: null,
    customer_id: null,

    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    authenticator: inject.service(),
    firebaseWrapper: inject.service(),
    ajax: inject.service(),

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    isLoading: false,

    CreateAccountValidations: _merlinValidationsCreateAccount['default'],

    signupFormOptions: {
      fields: [{
        id: "nameOrganization",
        label: "ORGANIZATION NAME",
        focus: true
      }, {
        id: "nameFirst",
        label: "FIRST NAME"
      }, {
        id: "nameLast",
        label: "LAST NAME"
      }, {
        id: "email",
        label: "YOUR EMAIL ADDRESS",
        autocapitalize: "off",
        spellcheck: false,
        autocorrect: "off"
      }, {
        id: "password",
        label: "PASSWORD",
        type: "password",
        autocapitalize: "off",
        spellcheck: false,
        autocorrect: "off"
      }, {
        id: "confirmPassword",
        label: "CONFIRM PASSWORD",
        type: "password",
        autocapitalize: "off",
        spellcheck: false,
        autocorrect: "off"
      }],

      data: {
        nameOrganization: "",
        organizationSize: "",
        sport: "",
        nameFirst: "",
        nameLast: "",
        email: "",
        password: "",
        confirmPassword: ""
      },

      buttons: [{
        id: "signup",
        label: "Start Using APEX"
      }],

      serverErrors: {}
    },

    ///////////////////////////
    ////////  Initialization  ////////
    ///////////////////////////

    setup: function setup() {
      set(this, "signupFormOptions.data.nameOrganization", get(this, 'orgName'));
      set(this, "signupFormOptions.data.nameFirst", get(this, 'firstName'));
      set(this, "signupFormOptions.data.nameLast", get(this, 'lastName'));
      set(this, "signupFormOptions.data.email", get(this, 'email'));
    },

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    trySubmission: function trySubmission(result) {
      var _this = this;

      var data = {
        orgName: get(result, 'nameOrganization'),
        firstName: get(result, 'nameFirst'),
        lastName: get(result, 'nameLast'),
        email: get(result, 'email').toLowerCase(),
        password: get(result, 'password'),
        customer_id: get(this, 'customer_id')
      };

      // Activate the loading animation.
      set(this, "isLoading", true);

      get(this, 'ajax').request(_merlinConfigEnvironment['default'].falconer + "account/finish_new_account", {
        method: 'POST',
        data: data
      }).then(function () {
        // Proceed to success.
        _this.transitionToRoute('signup_success');
      }).then(function () {
        set(_this, "isLoading", false);
      })['catch'](function (error) {
        _this.errorHandler.throwIt(error);
      });
    },

    ///////////////////////////
    ////////  Actions  ////////
    ///////////////////////////

    actions: {
      submittedSignup: function submittedSignup() {
        this.trySubmission(arguments[1]);
      }
    }

  });
});