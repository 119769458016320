define('merlin/routes/about', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({

    /////////////////////////////////////
    ////////  Lifecycle Methods  ////////
    /////////////////////////////////////

    beforeModel: function beforeModel(transition) {
      this.transitionTo('index', {
        queryParams: transition.queryParams
      });
    }
  });
});