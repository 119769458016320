define("merlin/controllers/settings/terms", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    navigationOptions: {
      type: "detail",
      title: "Terms of Service",
      left: {
        icon: "back",
        label: "Settings",
        actionName: "goTo",
        actionParameter: "settings",
        mobileOnly: true
      }
    }
  });
});