define('merlin/routes/videos/inbox/video', ['exports', 'ember'], function (exports, _ember) {
	var Route = _ember['default'].Route;
	var inject = _ember['default'].inject;
	var get = _ember['default'].get;
	var RSVP = _ember['default'].RSVP;
	exports['default'] = Route.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		videoAuthenticator: inject.service(),

		///////////////////////////
		////////  Methods  ////////
		///////////////////////////

		model: function model(params) {
			var _this = this;

			var session = get(this, 'session');
			var user = get(session, 'currentUser');

			var promises = {
				video: this.store.findRecord('video', params.video_id),
				token: get(this, "videoAuthenticator").getToken(user)
			};

			return RSVP.hash(promises)['catch'](function (error) {
				_this.errorHandler.throwIt(error);
			});
		}
	});
});