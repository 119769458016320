define('merlin/serializers/person-list', ['exports', 'emberfire/serializers/firebase'], function (exports, _emberfireSerializersFirebase) {
  exports['default'] = _emberfireSerializersFirebase['default'].extend({

    attrs: {
      person_list_lists: { embedded: 'always' }
    },

    // Square peg, circle hole
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {

      payload.person_list_lists = {};

      for (var attribute in payload) {
        if (payload.hasOwnProperty(attribute)) {
          // console.log(attribute, payload[attribute]);
          if (attribute !== "id" && attribute !== "person_list_lists") {
            payload.person_list_lists[attribute] = payload[attribute];
            delete payload[attribute];
          }
        }
      }
      return this._super.apply(this, arguments);
    }

  });
});
// Circle peg, square hole
// serialize(snapshot, options) {
//   const originalJSON = this._super(...arguments);
//   console.log(originalJSON);
//   // let newJSON = {};
//   // let lists = originalJSON.lists;
//   // console.log(snapshot, options, originalJSON);
//   // for (var i = 0; i < lists.length; i++) {
//   //   newJSON[lists[i]] = true;
//   // }
//
//   return originalJSON;
//   return newJSON;
// },