define('merlin/serializers/video', ['exports', 'emberfire/serializers/firebase'], function (exports, _emberfireSerializersFirebase) {
	exports['default'] = _emberfireSerializersFirebase['default'].extend({
		attrs: {
			athletes: { embedded: 'always' },
			coaches: { embedded: 'always' },
			// lists: { embedded: 'always' },
			reviews: { embedded: 'always' },
			tags: { embedded: 'always' }
		}
	});
});
// import DS from 'ember-data';